import ApiClient from './ApiClient';

class BotExpress extends ApiClient {
  constructor() {
    super('bot_expresses', { accountScoped: true });
  }

  getBotExpressList(){
    return axios.get(`${this.url}`);
  }
}

export default new BotExpress();
