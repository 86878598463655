<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.COMMUNAL_WHATSAPP.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.COMMUNAL_WHATSAPP.DESC')"
    />
    <div class="medium-8 columns">
      <label>
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.LABEL') }}
        <select v-model="provider" disabled="true">
          <option value="twilio">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.TWILIO') }}
          </option>
          <option value="360dialog">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.360_DIALOG') }}
          </option>
          <option v-if="provider !== 'twilio'" value="vonage">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.VONAGE') }}
          </option>
        </select>
      </label>
    </div>

    <twilio
      v-if="provider === 'twilio'"
      type="whatsapp"
      :whatsapp-inbox="false"
      :communal-inboxes-details="communalInboxes"
    ></twilio>
    <three-sixty-dialog-whatsapp
      v-else
      :whatsapp-inbox="false"
      :communal-inboxes-details="communalInboxes"
    />
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader';
import Twilio from './Twilio';
import ThreeSixtyDialogWhatsapp from './360DialogWhatsapp';
import { mapGetters } from 'vuex';

export default {
  components: {
    PageHeader,
    Twilio,
    ThreeSixtyDialogWhatsapp,
  },
  computed: {
    ...mapGetters({
      communalInboxes: 'accounts/getCommunalInboxes',
    }),
  },
  mounted() {
    this.provider = this.communalInboxes.provider;
  },
  data() {
    return {
      provider: 'twilio',
    };
  },
};
</script>
