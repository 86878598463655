export const GROUP_BY_FILTER = {
  1: { id: 1, period: 'day' },
  2: { id: 2, period: 'week' },
  3: { id: 3, period: 'month' },
  4: { id: 4, period: 'year' },
};




export const METRIC_OPTIONS = [
  {name:'Sale by Days',id:'sale_by_days'},
  {name:'Sale by Weeks',id:'sale_by_weeks'},
]