<template>
  <div>
    <!-- Open the initial modal firs -->
    <!-- <ModalTemplate
      :isOpen="isOpen"
      :on-close="onClose"
      modalMainContainerClass="squidLevelsModal"
      closeBtnClass="modal_close_btn_left"
      closeIcon="chevron-left"
      title=""
    >
    <div>
      Initial Modal
      </div>
    </ModalTemplate> -->


    <ModalTemplate
      :isOpen="isOpen"
      :on-close="onClose"
      modalMainContainerClass="squidLevelsModal"
      closeBtnClass="modal_close_btn_left"
      closeIcon="chevron-left"
      :title="$t('D_BOARD.SQUID.TITLE')"
    >
    <div class="squidLevelsModal_container">

      <div class="card_header">
        <div class="switch_btn">
          <span @click="goPreviouse(active_index)" :class="active_index === 0?'disable_btn':'enable_btn'">
            <fluent-icon icon="chevron-left" />
          </span>
        </div>
        <div>
          <div class="logo">
            <img :src="getLevelLogo(active_index)" alt="">
            <!-- <img src="~dashboard/assets/images/lavel_logo_placeholder.png" alt=""> -->
          </div>
        </div>
        <div class="switch_btn" >
          <span @click="goNext(active_index)" :class="active_index === levels.length-1?'disable_btn':'enable_btn'">
            <fluent-icon icon="chevron-right" />
          </span>
        </div>
        <div>
        </div>
      </div>
      <div class="card_title">
        <h2 class="">{{levels[active_index].name}}</h2>
      </div>
      <div class="progress_bar">
        <ProgressBar :progress="gvmCompletePercent" />
      </div>
      <div class="gmv_lavel">
        <h3><span>{{score.total_gmv}}</span>/{{levels[active_index].target_gmv}} RM</h3>
        <p v-if="nextLevel" class="hint">{{$t('D_BOARD.LEVELS.GMV_UPGRADE_1')}} {{levels[active_index].target_gmv}} {{$t('D_BOARD.LEVELS.GMV_UPGRADE_2')}} {{nextLevel.name}}</p>
      </div>
      <div v-if="previousLevel" class="gmv_alert">
        <p>{{$t('D_BOARD.LEVELS.ALERT_1')}} {{previousLevel.target_gmv}} {{$t('D_BOARD.LEVELS.ALERT_2')}}</p>
      </div>
      <div class="benifits_wrap">
        <h2 class="benefit_title">{{levels[active_index].name}} {{$t('D_BOARD.LEVELS.BENEFIT')}}</h2>
        <ul class="benifits">
            <li v-for="item in benifits" class="benifit" :key="item">
              <p class="title">{{item.name}}</p>
              <div class="info">
                <div class="icon">
                  <fluent-icon :icon="item.icon" />
                </div>
                <div class="description">{{item.value}}</div>
              </div>
            </li>
        </ul>
      </div>

      <div>
        <ul class="docs">
          <li>
            {{$t('D_BOARD.DOC_LIST.TRAINING')}}
            <span>
              <fluent-icon icon="chevron-right" />
            </span>
          </li>
          <li>
            {{$t('D_BOARD.DOC_LIST.EARN_POINT')}}
            <span>
              <fluent-icon icon="chevron-right" />
            </span>
          </li>
        </ul>
      </div>

      <div style="margin-top: 24px;">
        <woot-button class-names="button--dashboard-outline clode_sq_modal" variant="outline" @click="onClose">
          {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.CLOSE")}}
        </woot-button>
      </div>

    </div>
    </ModalTemplate>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../../../components/Modal.vue';
import ModalTemplate from '../../../components/ModalTemplate.vue';
import ProgressBar from '../../../components/ProgressBar.vue';
import alertMixin from 'shared/mixins/alertMixin';
// const placeholder = require('@/assets/images/lavel_logo_placeholder.png');

import  placeholderImg from "assets/images/lavel_logo_placeholder.png";

export default {
  components:{
    Modal,
    ModalTemplate,
    ProgressBar,
  },
  mixins: [alertMixin],
  props:{
    isOpen: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data(){
    return {
      active_index: 0,
    }
  },
  computed:{
    ...mapGetters({
      unsortedLevels: 'levels/getLevels',
      score: 'score/getScore',
    }),
    levels(){
      return this.unsortedLevels.sort((a,b)=>a.rank-b.rank);
    },
    gvmCompletePercent(){
      const targetGMV = this.levels[this.active_index]?.target_gmv || 1;
      const percentage = (this.score.total_gmv / targetGMV) * 100;
      return Math.min(Math.max(percentage, 0), 100).toFixed(2);
    },
    nextLevel(){
      if (this.active_index < this.levels.length - 1) {
        return this.levels[this.active_index + 1]
      }
      return null;
    },
    previousLevel(){
      if (this.active_index > 0) {
        return this.levels[this.active_index - 1];
      }
      return null;
    },
    benifits(){
      const {support,promotion,offline_courses_discount,subscription_fee_discount} = this.levels[this.active_index]?.features || {};

      const result = [];
      const supportEl = {
          name: this.$t(`D_BOARD.SCORE.SUPPORT.LABEL`),
          icon:"help-support",
          value: this.$t(`D_BOARD.SCORE.SUPPORT.${support?.toUpperCase()}`)
      }
      if ('support' in this.score?.benefits) {
          result.push(supportEl)
      }
      const promotionEl = {
          name: this.$t(`D_BOARD.SCORE.PROMOTION.LABEL`),
          icon:"hand-mike",
          value: this.$t(`D_BOARD.SCORE.PROMOTION.${promotion?.toUpperCase()}`)
      }
      if ('promotion' in this.score?.benefits) {
          result.push(promotionEl)
      }

      if ('offline_courses_discount' in this.score?.benefits) {
          let courseDiscount = "";
          if (offline_courses_discount === 0) {
              courseDiscount = this.$t(`D_BOARD.SCORE.COURSES.NO_DISCOUNT`)
          }else if (offline_courses_discount === 100) {
              courseDiscount = this.$t(`D_BOARD.SCORE.COURSES.FREE`)
          }else {
              courseDiscount = offline_courses_discount + "% " +  this.$t(`D_BOARD.SCORE.COURSES.DISCOUNT`)
          }
          const course = {
              name: this.$t(`D_BOARD.SCORE.COURSES.LABEL`),
              icon:"discount",
              value: courseDiscount
          }
          result.push(course)
      }

      if ('subscription_fee_discount' in this.score?.benefits) {
          let subscriptionValue = "";
          if (subscription_fee_discount === 0) {
              subscriptionValue = this.$t(`D_BOARD.SCORE.SUBSCRIPTION.NO_DISCOUNT`)
          }else if (subscription_fee_discount === 100) {
              subscriptionValue = this.$t(`D_BOARD.SCORE.SUBSCRIPTION.FREE`)
          }else {
              subscriptionValue = subscription_fee_discount + "% " +  this.$t(`D_BOARD.SCORE.SUBSCRIPTION.DISCOUNT`)
          }
          const subscription = {
              name: this.$t(`D_BOARD.SCORE.SUBSCRIPTION.LABEL`),
              icon:"dollar-round",
              value: subscriptionValue
          }
          result.push(subscription)
      }

      return result;
    },
  },
  mounted(){
    this.$store.dispatch('levels/fetchLevels')
  },
  methods:{
    goNext(index){
      if (this.active_index < this.levels.length - 1) {
        this.active_index += 1;
      }
    },
    goPreviouse(index){
      if (this.active_index > 0) {
        this.active_index -= 1;
      }
    },
    getLevelLogo(index){
      return this.levels[index].logo_url ||placeholderImg;
    },
    // end methods
  },
}
</script>

<style lang="scss" scoped>
.squidLevelsModal{
  width: 440px;
}
.squidLevelsModal_container{
  .card_header{
    display: grid;
    grid-template-columns: 40px 160px 40px;
    align-items: center;
    justify-items: center;
    width: fit-content;
    margin: 40px auto auto auto;

    div{
      .logo{
        width: 160px;
        height: 160px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
}
.switch_btn{
  span{
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .enable_btn{
    color: #858585;
    &:hover{
      color: #8D25F6;
    }
  }
  .disable_btn{
    color: #E0E0E0;
  }
}
.progress_bar{
  max-width: 240px;
  margin: 12px auto auto auto;
}
.card_title{
  margin-top: 24px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: #CD7F32;
}
.gmv_lavel{
  margin: 12px;
  h3{
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    // color: #1F1F1F;
    span{
      font-weight: 700;
      font-size: 28px;
      color: #8D25F6;
    }
  }
  .hint{
    font-weight: 500;
    font-size: 12px;
    color: #858585;
  }
}
.gmv_alert{
  margin-top: 24px;
  border-radius: 6px;
  padding: 10px 12px;
  background-color: #FFF4E5;
  p{
    font-weight: 500;
    font-size: 12px;
    color: #FFAA00;
  }
}
.benifits_wrap{
  margin: 24px 0 0 0;
  padding: 16px;
  border-radius: 10px;
  background-color: #F2E7FE;
  .benefit_title{
    font-weight: 500;
    font-size: 16px;
    color: #8D25F6;
  }

  .benifits{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 16px;
    list-style: none;
    margin-top: 12px;

    .benifit{
      .title{
        font-weight: 500;
        font-size: 12px;
        color: #858585;
      }
      .info{
        display: grid;
        grid-template-columns: 20px 1fr;
        gap: 4px;
        margin-top: 4px;

        .icon{
          display: block;
          margin: auto;
          width: 16px;
          height: 16px;
          svg{
            color: #8D25F6;
          }
        }
        .description{
          font-weight: 500;
          font-size: 12px;
          color: #1F1F1F;
        }
      }
    }
  }
}
.docs{
  list-style: none;
  margin: 24px 0 0 0;
  li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;
    border-radius: 6px;
    border: 1px solid #EEEEEE;
    font-weight: 500;
    font-size: 14px;
    color: #8D25F6;

    &:not(:first-child){
      margin-top: 16px;
    }

    span{
      svg{
        width: 10px;
        height: 10px;
        color: #858585;
      }
    }
  }
}
</style>

<style lang="scss">
// global css
.squidLevelsModal{
  width: 440px;

  .clode_sq_modal{
    width: 100%;
  }
}
</style>