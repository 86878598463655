/* global axios */
import ApiClient from './ApiClient';

class LevelsApI extends ApiClient {
  constructor() {
    super('levels', { accountScoped: true });
  }

  getLevels(){
    return axios.get(`${this.url}`);
  }

}

export default new LevelsApI();
