<template>
  <div class="column">
    <woot-modal-header
      :header-title="$t('CALENDAR_MGMT.ADD.CREATE_MEETING_TITLE')"
    />
    <div class="row modal-content">
      <!-- Meeting Title -->
      <div class="medium-12 columns">
        <label :class="{ error: $v.calendar_form.meetingTitle.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.MEETING.LABEL') }}
          <input
            v-model.trim="calendar_form.meetingTitle"
            type="text"
            :placeholder="$t('CALENDAR_MGMT.ADD.FORM.MEETING.PLACEHOLDER')"
            @input="$v.calendar_form.meetingTitle.$touch"
          />
          <span v-if="$v.calendar_form.meetingTitle.$error" class="message">
            {{ $t('CALENDAR_MGMT.ADD.FORM.MEETING.ERROR') }}
          </span>
        </label>
      </div>
      <!-- Meeting Title -->

      <!-- Description/Instructions -->
      <div class="medium-12 columns">
        <label :class="{ error: $v.calendar_form.meetingDescription.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.MEETING_DESCIPTION.LABEL') }}
          <textarea
            v-model.trim="calendar_form.meetingDescription"
            type="text"
            :placeholder="
              $t('CALENDAR_MGMT.ADD.FORM.MEETING_DESCIPTION.PLACEHOLDER')
            "
            @input="$v.calendar_form.meetingDescription.$touch"
          />
          <span
            v-if="$v.calendar_form.meetingDescription.$error"
            class="message"
          >
            {{ $t('CALENDAR_MGMT.ADD.FORM.MEETING_DESCIPTION.ERROR') }}
          </span>
        </label>
      </div>
      <!-- Description/Instructions -->

      <!--Meeting Link  -->
      <div class="medium-12 columns">
        <label :class="{ error: $v.calendar_form.meetingLink.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.MEETING_LINK.LABEL') }}
          <div class="input-group">
            <span class="input-group-label">{{
              prefix + `${currentUserId}/`
            }}</span>
            <input
              v-model.trim="calendar_form.meetingLink"
              class="input-group-field"
              type="text"
              placeholder="sales"
              @input="$v.calendar_form.meetingLink.$touch"
            />
          </div>
          <span v-if="$v.calendar_form.meetingLink.$error" class="message">
            {{ $t('CALENDAR_MGMT.ADD.FORM.MEETING_LINK.ERROR') }}
          </span>
        </label>
      </div>
      <!-- Meeting Link -->

      <!-- Buffer time between your meetings -->
      <div class="medium-6 columns pr-1">
        <label :class="{ error: $v.calendar_form.maximumBufferTime.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.MAXIMUM_BUFFER_TIME.LABEL') }}
          <div class="input-group">
            <input
              v-model.trim="calendar_form.maximumBufferTime"
              type="number"
              class="input-group-field"
              @input="$v.calendar_form.maximumBufferTime.$touch"
            />
            <span class="input-group-label">Minutes</span>
          </div>
          <span
            v-if="
              $v.calendar_form.maximumBufferTime.$error &&
                !$v.calendar_form.maximumBufferTime.between
            "
            class="message"
          >
            {{ $t('CALENDAR_MGMT.ADD.FORM.MAXIMUM_BUFFER_TIME.ERROR_BETWEEN') }}
            {{ $v.calendar_form.maximumBufferTime.$params.between.min }} &
            {{ $v.calendar_form.maximumBufferTime.$params.between.max }}
          </span>
        </label>
      </div>
      <!-- Buffer time between your meetings -->

      <!-- Duration -->
      <div class="medium-6 columns ">
        <label :class="{ error: $v.calendar_form.duration.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.MAXIMUM_DURATION.LABEL') }}
          <div class="input-group">
            <input
              v-model.trim="calendar_form.duration"
              type="number"
              class="input-group-field"
              @input="$v.calendar_form.duration.$touch"
            />
            <span class="input-group-label">Minutes</span>
          </div>
          <span
            v-if="
              $v.calendar_form.duration.$error &&
                !$v.calendar_form.duration.between
            "
            class="message"
          >
            {{ $t('CALENDAR_MGMT.ADD.FORM.MAXIMUM_DURATION.ERROR_BETWEEN') }}
            {{ $v.calendar_form.duration.$params.between.min }} &
            {{ $v.calendar_form.duration.$params.between.max }}
          </span>
        </label>
      </div>
      <!-- Duration -->

      <!-- Max allowed meetings per day -->
      <div class="medium-6 columns pr-1">
        <label :class="{ error: $v.calendar_form.maximumMeeting.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.MAXIMUM_MEETING.LABEL') }}
          <input
            v-model.trim="calendar_form.maximumMeeting"
            type="number"
            :placeholder="
              $t('CALENDAR_MGMT.ADD.FORM.MAXIMUM_MEETING.PLACEHOLDER')
            "
            @input="$v.calendar_form.maximumMeeting.$touch"
          />
          <span v-if="$v.calendar_form.maximumMeeting.$error" class="message">
            {{ $t('CALENDAR_MGMT.ADD.FORM.MAXIMUM_MEETING.ERROR') }}
          </span>
          <span v-if="$v.calendar_form.maximumMeeting.$error" class="message">
            {{ $t('CALENDAR_MGMT.ADD.FORM.MAXIMUM_MEETING.ERROR_INVALID_MAX') }}
          </span>
        </label>
      </div>
      <!-- Max allowed meetings per day -->

      <!-- Notice time before your meetings -->
      <div class="medium-6 columns">
        <label :class="{ error: $v.calendar_form.notice_time.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.NOTICE_TIME.LABEL') }}
          <div class="input-group">
            <input
              v-model.trim="calendar_form.notice_time"
              type="number"
              class="input-group-field"
              min="0"
              @input="$v.calendar_form.notice_time.$touch"
            />
            <span class="input-group-label">Minutes</span>
          </div>
          <span v-if="$v.calendar_form.notice_time.$error" class="message">
            {{ $t('CALENDAR_MGMT.ADD.FORM.NOTICE_TIME.ERROR_MIN') }}
          </span>
        </label>
      </div>
      <!-- Notice time before your meetings -->

      <!-- Meeting reminder time -->
      <div class="medium-6 columns pr-1">
        <label :class="{ error: $v.calendar_form.reminder_time.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.REMINDER_TIME.LABEL') }}
          <div class="input-group">
            <input
              v-model.trim="calendar_form.reminder_time"
              type="number"
              class="input-group-field"
              :placeholder="
                $t('CALENDAR_MGMT.ADD.FORM.REMINDER_TIME.PLACEHOLDER')
              "
              @input="$v.calendar_form.reminder_time.$touch"
              min="0"
            />
            <span class="input-group-label">Minutes</span>
          </div>
          <span v-if="$v.calendar_form.reminder_time.$error" class="message">
            {{ $t('CALENDAR_MGMT.ADD.FORM.REMINDER_TIME.ERROR_MIN') }}
          </span>
        </label>
      </div>
      <!-- Meeting reminder time -->

      <!-- Select Inbox -->
      <div class="medium-6 columns">
        <label :class="{ error: $v.calendar_form.inbox_id.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.INBOX_ID.PLACEHOLDER') }}
          <select v-model="calendar_form.inbox_id">
            <option v-for="inbox of inboxes" :key="inbox.id" :value="inbox.id">
              {{ inbox.label }}
            </option>
          </select>
          <span v-if="$v.calendar_form.inbox_id.$error" class="message">
            {{ $t('CALENDAR_MGMT.ADD.FORM.INBOX_ID.ERROR') }}
          </span>
        </label>
      </div>
      <!-- Select Inbox -->

      <!-- Select confirmation message -->
      <div class="medium-12 columns">
        <label
          :class="{ error: $v.calendar_form.confirmation_msg.message.$error }"
        >
          {{ $t('CALENDAR_MGMT.ADD.FORM.CONFIRMATION_MSG.LABEL') }}
          <!-- v-on-clickaway="hideMentions" -->
          <canned-response
            v-if="
              showMentions && hasSlashCommand && nonCommunalInboxes.length === 0
            "
            top="37"
            :search-key="mentionSearchKey"
            @click="replaceText"
          />
          <textarea
            v-if="calendar_form.confirmation_msg"
            v-model="message_content"
            rows="4"
            :placeholder="$t('AUTOMATION.ADD_MESSAGE_CONTENT')"
            class="action-message"
          ></textarea>
          <span
            v-if="$v.calendar_form.confirmation_msg.message.$error"
            class="message"
          >
            {{ $t('CALENDAR_MGMT.ADD.FORM.CONFIRMATION_MSG.ERROR') }}
          </span>
        </label>
      </div>
      <!-- Select confirmation message -->

      <!-- Select reminder message -->
      <div class="medium-12 columns">
        <label :class="{ error: $v.calendar_form.reminder_msg.message.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.REMINDER_MSG.LABEL') }}
          <!-- v-on-clickaway="hideMentions" -->
          <canned-response
            v-if="
              reminder_msg_showMentions &&
                reminder_msg_hasSlashCommand &&
                reminder_msg_nonCommunalInboxes.length === 0
            "
            top="37"
            :search-key="reminder_msg_mentionSearchKey"
            @click="reminder_msg_replaceText"
          />
          <textarea
            v-if="calendar_form.reminder_msg"
            v-model="reminder_msg_content"
            rows="4"
            :placeholder="$t('AUTOMATION.ADD_MESSAGE_CONTENT')"
            class="action-message"
          ></textarea>
          <span
            v-if="$v.calendar_form.reminder_msg.message.$error"
            class="message"
          >
            {{ $t('CALENDAR_MGMT.ADD.FORM.REMINDER_MSG.ERROR') }}
          </span>
        </label>
      </div>
      <!-- Select reminder message -->

      <!-- <div class="medium-12 columns">
        <label :class="{ error: $v.calendar_form.reminderMessage.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.CONFIG_MESSAGE.LABEL') }}
          <input v-model.trim="calendar_form.reminderMessage" type="text" :placeholder="
            $t('CALENDAR_MGMT.ADD.FORM.CONFIG_MESSAGE.PLACEHOLDER')
          " @input="$v.calendar_form.reminderMessage.$touch" />
          <span v-if="$v.calendar_form.reminderMessage.$error" class="message">
            {{ $t('CALENDAR_MGMT.ADD.FORM.CONFIG_MESSAGE.ERROR') }}
          </span>
        </label>
      </div> -->

      <!-- Timezone -->
      <div class="medium-12 columns">
        <label :class="{ error: $v.calendar_form.timezone.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.TIMEZONE.LABEL') }}
          <select v-model="calendar_form.timezone">
            <option
              v-for="timezone of timezoneNames"
              :key="timezone"
              :value="timezone"
            >
              {{ timezone }}
            </option>
          </select>
          <span v-if="$v.calendar_form.timezone.$error" class="message">
            {{ $t('CALENDAR_MGMT.ADD.FORM.TIMEZONE.ERROR') }}
          </span>
        </label>
      </div>
      <!-- Timezone -->

      <!-- Conference Type -->
      <div class="medium-12 columns">
        <label :class="{ error: $v.calendar_form.location.type.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.CONFERENCE_TYPE.LABEL') }}
          <select v-model="calendar_form.location.type">
            <option
              v-for="conference_type in ConferenceType"
              :key="conference_type.value"
              :value="conference_type.value"
            >
              {{ conference_type.label }}
            </option>
          </select>
          <span v-if="$v.calendar_form.location.type.$error" class="message">
            {{ $t('CALENDAR_MGMT.ADD.FORM.CONFERENCE_TYPE.ERROR') }}
          </span>
        </label>
      </div>
      <!-- Conference Type -->

      <div
        class="medium-12 columns"
        v-show="calendar_form.location.type == 'custom'"
      >
        <label :class="{ error: $v.calendar_form.location.custom.$error }">
          {{ $t('CALENDAR_MGMT.ADD.FORM.CONFERENCE_TYPE.CUSTOM_LABEL') }}
          <textarea
            v-model.trim="calendar_form.location.custom"
            type="text"
            :placeholder="
              $t('CALENDAR_MGMT.ADD.FORM.CONFERENCE_TYPE.CUSTOME_PLACEHOLDER')
            "
            @input="$v.calendar_form.location.custom.$touch"
          />
          <span v-if="$v.calendar_form.location.custom.$error" class="message">
            {{ $t('CALENDAR_MGMT.ADD.FORM.CONFERENCE_TYPE.ERROR') }}
          </span>
        </label>
      </div>

      <!-- // Availability Start -->
      <div class="medium-12 columns">
        <section>
          <label>
            {{ $t('CALENDAR_MGMT.ADD.FORM.AVAILABILITY.LABEL') }}
          </label>
          <div class="medium-12 columns filters-wrap">
            <businessHours
              v-for="(business_hour, i) in calendar_form.business_hours"
              :key="i"
              v-model="calendar_form.business_hours[i]"
              :value="$v.calendar_form.business_hours[i]"
              :availableWeekDays="availableWeekDays"
              @removeAction="removeAction(i)"
            />
            <div class="filter-actions">
              <woot-button
                icon="add"
                color-scheme="success"
                variant="smooth"
                size="small"
                :is-disabled="!availableWeekDays.length"
                @click="addMoreBusinessHours"
              >
                {{
                  $t('CALENDAR_MGMT.ADD.FORM.AVAILABILITY.ACTION_BUTTON_LABEL')
                }}
              </woot-button>
            </div>
          </div>
        </section>
      </div>
      <!-- // Availability End -->

      <div class="medium-12 columns">
        <div class="modal-footer justify-content-end w-full">
          <spinner size="large" v-if="isLoading" />
          <woot-button class="button clear" @click.prevent="onClose">
            {{ $t('CALENDAR_MGMT.BTN_CANCEL') }}
          </woot-button>
          <woot-button @click="onSubmit">
            {{
              this.prefilledForm
                ? $t('CALENDAR_MGMT.BTN_UPDATE')
                : $t('CALENDAR_MGMT.BTN_CREATE')
            }}
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import businessHours from './BusinessHours.vue';
import moment from 'moment-timezone';
import Spinner from 'shared/components/Spinner.vue';

import {
  required,
  minLength,
  maxLength,
  requiredIf,
  between,
  minValue,
  maxValue,
  sameAs,
} from 'vuelidate/lib/validators';
import { SESSSION_PREFIX_URL, CONFERENCE_TYPE, BUSINESS_WEEK_DAYS } from './constants.js';
import { ulid } from 'ulid';
import alertMixin from 'shared/mixins/alertMixin';
import CalendarApi from '../../../../api/calendar';
import { mapGetters } from 'vuex';
import CannedResponse from '../../../../components/widgets/conversation/CannedResponse.vue';

// import CannedResponse from './conversation/CannedResponse.vue';

export default {
  name: 'calendar-meeting-add',
  components: {
    businessHours,
    Spinner,
    CannedResponse,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    currentUserId: {
      type: Number,
    },
    refresh: {
      type: Function,
    },

    prefilledForm: {
      type: Object,
    },
  },
  validations: {
    calendar_form: {
      meetingTitle: { required },
      meetingDescription: { required },
      meetingLink: { required },
      maximumMeeting: {
        required,
        // maxValue: minValue(
        //   1440 /
        //     ((this.calendar_form?.duration || 1) +
        //       (this.calendar_form?.buffer_time || 0))
        // ),
      },
      duration: {
        required,
        between: between(1, 1440),
      },
      maximumBufferTime: {
        required,
        between: between(0, 1439),
      },
      notice_time: {
        required,
        minValue: minValue(0),
      },
      reminder_time: {
        required,
        minValue: minValue(0),
      },
      timezone: { required },
      inbox_id: { required },
      confirmation_msg: {
        message: { required },
      },
      reminder_msg: {
        message: { required },
      },
      business_hours: {
        required,
        $each: {
          day: { required },
          start_hour: { required },
          end_hour: { required },
        },
      },
      location: {
        type: {
          required,
        },
        custom: {
          required: requiredIf(function(model) {
            return model.type === 'custom';
          }),
        },
      },
    },
  },
  mounted() {
    const currentTimezone = moment.tz.guess();
    if (currentTimezone) {
      this.calendar_form.timezone = currentTimezone;
    }

    if (this.prefilledForm) {
      this.calendar_form = this.prefilledForm;
      this.message_content = this.calendar_form.confirmation_msg['message'];
      this.reminder_msg_content = this.calendar_form.reminder_msg['message'];
    }
    console.log('mounted', this.prefilledForm, this.calendar_form);
    // Fetch API Call
    // this.$store.dispatch('getCannedResponse');
  },
  mounted() {
    const currentTimezone = moment.tz.guess();
    if (currentTimezone) {
      this.calendar_form.timezone = currentTimezone;
    }

    console.log('mounted', this.prefilledForm);
    if (this.prefilledForm) {
      this.calendar_form = this.prefilledForm;
      this.message_content = this.calendar_form.confirmation_msg['message'];
      this.reminder_msg_content = this.calendar_form.reminder_msg['message'];
    }
    console.log('mounted', this.prefilledForm, this.calendar_form);
    // Fetch API Call
    // this.$store.dispatch('getCannedResponse');
  },
  data() {
    return {
      show: true,
      ConferenceType: CONFERENCE_TYPE,
      isLoading: false,
      calendar_form: {
        meetingTitle: '',
        meetingDescription: '',
        meetingUniqueLink: ulid(),
        meetingLink: '',
        maximumMeeting: 5,
        duration: 15,
        maximumBufferTime: 15,
        reminder_time: 15,
        timezone: '',
        business_hours: [
          {
            day: 'Mon',
            start_hour: '11:00',
            end_hour: '20:00',
          },
        ],
        // inbox_id: 61,
        inbox_id: null,
        notice_time: 0,
        confirmation_msg: { template_name: '', message: '' },
        reminder_msg: { template_name: '', message: '' },
        location: { type: 'google_meet', custom: '' },
      },

      message_content: '',
      mentionSearchKey: '',
      showMentions: false,
      hasSlashCommand: false,
      nonCommunalInboxes: [],

      reminder_msg_content: '',
      reminder_msg_mentionSearchKey: '',
      reminder_msg_showMentions: false,
      reminder_msg_hasSlashCommand: false,
      reminder_msg_nonCommunalInboxes: [],

      availableWeekDays: BUSINESS_WEEK_DAYS,
    };
  },
  computed: {
    ...mapGetters({
      dialogFlowEnabledInboxes: 'inboxes/dialogFlowEnabledInboxes',
      // connedResponse: 'getCannedResponses',
    }),
    inboxes() {
      // this.dialogFlowEnabledInboxes.push({
      //   id: 61,
      //   name: 'Static-testing',
      // });

      return (
        this.dialogFlowEnabledInboxes
          // .filter(inbox => {
          //   return inbox;
          // })
          .map(inbox => ({ ...inbox, label: inbox.name, value: inbox.id }))
      );
    },
    prefix() {
      return SESSSION_PREFIX_URL;
    },
    selected() {
      return moment.tz.guess();
    },
    timezoneNames() {
      return moment.tz.names();
    },
  },
  watch: {
    message_content(updatedMessage) {

      this.hasSlashCommand = updatedMessage && updatedMessage[0] === '/';
      const hasNextWord = updatedMessage && updatedMessage.includes(' ');
      const isShortCodeActive = this.hasSlashCommand && !hasNextWord;
      // console.log("aslkdas", updatedMessage, isShortCodeActive, this.nonCommunalInboxes.length);

      if (isShortCodeActive && this.nonCommunalInboxes.length === 0) {
        this.mentionSearchKey = updatedMessage.substr(1, updatedMessage.length);
        this.showMentions = true;
      } else if (
        isShortCodeActive &&
        this.nonCommunalInboxes.length !== 0 &&
        updatedMessage.length < 5
      ) {
        alert('Please select only 360 whatsapp inbox to use canned response.');
        this.showAlert(
          'Please select only 360 whatsapp inbox to use canned response.'
        );
      } else {
        this.mentionSearchKey = '';
        this.showMentions = false;
        this.calendar_form.confirmation_msg['message'] = updatedMessage;
      }
    },
    reminder_msg_content(updatedMessage) {
      this.reminder_msg_hasSlashCommand =
        updatedMessage && updatedMessage[0] === '/';
      const hasNextWord = updatedMessage && updatedMessage.includes(' ');
      const isShortCodeActive =
        this.reminder_msg_hasSlashCommand && !hasNextWord;
      if (
        isShortCodeActive &&
        this.reminder_msg_nonCommunalInboxes.length === 0
      ) {
        this.reminder_msg_mentionSearchKey = updatedMessage.substr(
          1,
          updatedMessage.length
        );
        this.reminder_msg_showMentions = true;
      } else if (
        isShortCodeActive &&
        this.reminder_msg_nonCommunalInboxes.length !== 0 &&
        updatedMessage.length < 5
      ) {
        alert('Please select only 360 whatsapp inbox to use canned response.');
        this.reminder_msg_showAlert(
          'Please select only 360 whatsapp inbox to use canned response.'
        );
      } else {
        this.reminder_msg_mentionSearchKey = '';
        this.reminder_msg_showMentions = false;
        this.calendar_form.reminder_msg['message'] = updatedMessage;
      }
    },
    'calendar_form.business_hours':{
      deep:true,
      handler(selectedBusinessHours){
        this.availableWeekDays =  BUSINESS_WEEK_DAYS.filter(el=>{
          const isAdded = selectedBusinessHours.find(item=>item.day === el.id);
          return !isAdded;
        })
      },
    },
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('OnSubmit-522', this.$v.calendar_form);
        return;
      }

      let checkDays = new Set();
      for (const business_hour of this.calendar_form.business_hours) {
        if (checkDays.has(business_hour.day)) {
          this.showAlert(
            this.$t('CALENDAR_MGMT.ADD.FORM.BUSINESS_HOURS.DAY_MUST_BE_UNIQUE')
          );
          return;
        }
        if (
          moment(business_hour.start_hour, ['H:m']) >=
          moment(business_hour.end_hour, ['H:m'])
        ) {
          this.showAlert(
            this.$t('CALENDAR_MGMT.ADD.FORM.BUSINESS_HOURS.HOURS')
          );
          return;
        }
        checkDays.add(business_hour.day);
      }

      const bufferTimeNumber = parseInt(this.calendar_form.maximumBufferTime);
      const durationNumber = parseInt(this.calendar_form.duration);
      const maxMeetingsNumber = parseInt(this.calendar_form.maximumMeeting);
      const validMeetings = Math.floor(
        1440 / (durationNumber + bufferTimeNumber)
      );

      if (maxMeetingsNumber > validMeetings) {
        this.showAlert(
          this.$t('CALENDAR_MGMT.ADD.FORM.MAXIMUM_MEETING.ERROR_INVALID_MAX')
        );
        return;
      }
      // console.log('OnSubmit-536', this.calendar_form.business_hours);
      // return;
      try {
        const payload = {
          title: '',
          description: '',
          duration: '',
          availability: [],
          max_meetings: 1,
          buffer_time: 0,
          notice_time: 0,
          reminder_time: 0,
          confirmation_msg: {
            template_name: '',
            message:
              'Hi, saya cuba menghubungi anda sebentar tadi. Boleh saya tahu bila masa yang sesuai untuk saya buat panggilan berkenaan geran BSN?',
          },
          reminder_msg: {
            template_name: '',
            message:
              'Hi {{contact.name}}, I am {{agent.name}} the Peasy.AI digital marketing expert assigned to help you.',
          },
          inbox_id: 0,
          unique_link: '',
          location: { type: 'google_meet', custom: '' },
          timezone: '',
        };

        payload.title = this.calendar_form.meetingTitle;
        payload.description = this.calendar_form.meetingDescription;
        payload.duration = this.calendar_form.duration;
        payload.availability = this.calendar_form.business_hours;
        payload.buffer_time = this.calendar_form.maximumBufferTime;
        payload.max_meetings = this.calendar_form.maximumMeeting;
        payload.notice_time = this.calendar_form.notice_time;
        payload.reminder_time = this.calendar_form.reminder_time;
        payload.timezone = this.calendar_form.timezone;
        payload.unique_link = this.calendar_form.meetingLink;
        // payload.reminder_msg.message = this.calendar_form.reminderMessage;
        payload.inbox_id = this.calendar_form.inbox_id;
        payload.notice_time = this.calendar_form.notice_time;

        // payload.confirmation_msg = this.calendar_form.confirmation_msg;
        payload.confirmation_msg[
          'template_name'
        ] = this.calendar_form.confirmation_msg['template_name'];
        payload.confirmation_msg[
          'message'
        ] = this.calendar_form.confirmation_msg['message'];

        // payload.reminder_msg = this.calendar_form.reminder_msg;
        payload.reminder_msg['template_name'] = this.calendar_form.reminder_msg[
          'template_name'
        ];
        payload.reminder_msg['message'] = this.calendar_form.reminder_msg[
          'message'
        ];

        payload.location['type'] = this.calendar_form.location['type'];
        payload.location['custom'] = this.calendar_form.location['custom'];
        console.log('L592', payload, this.calendar_form, this.message_content);
        // return;

        // payload.max_meetings = this.calendar_form.maximumMeeting;

        try {
          this.isLoading = true;
          if (this.prefilledForm) {
            payload.id = this.prefilledForm.id;
            await CalendarApi.updateMeetingSession(payload);
            this.showAlert('Meeting session updated');
          } else {
            await CalendarApi.createMeetingSession(payload);
            this.showAlert('Meeting session created');
          }
          this.refresh();
          this.onClose();
        } catch (error) {
          console.log('create session error', error.response);
          this.showAlert(error?.response?.data?.message || error);
        } finally {
          this.isLoading = false;
        }

        // console.log('create payload ', this.calendar_form, payload);
      } catch (error) {
        console.log('OnSubmit-618', error);
        this.showAlert(this.$t('AUTOMATION.ADD.API.ERROR_MESSAGE'));
      }
    },
    async addMoreBusinessHours() {
      console.log('LF-244', this.calendar_form.business_hours,this.availableWeekDays );
      if (this.availableWeekDays.length === 0) {
        // no days available
        return
      }
      // take next day from available list
      await this.calendar_form.business_hours.push({
        day:  this.availableWeekDays[0].id,             // ex. 'Mon',
        start_hour: '11:00',
        end_hour: '22:00',
      });
    },
    removeAction(index) {
      if (this.calendar_form.business_hours.length <= 1) {
        this.showAlert(
          this.$t('CALENDAR_MGMT.ADD.FORM.BUSINESS_HOURS.MINIMUM_ONE_REQUIRED')
        );
      } else {
        this.calendar_form.business_hours.splice(index, 1);
      }
    },
    replaceText(message, templateName) {
      // console.log("L556", message, "alskdaslk" + templateName)
      setTimeout(() => {
        // this.templateName = templateName;
        this.message_content = message;
        this.calendar_form.confirmation_msg['template_name'] = templateName;
        this.calendar_form.confirmation_msg['message'] = message;
      }, 100);
    },
    hideMentions() {
      this.showMentions = false;
    },

    reminder_msg_replaceText(message, templateName) {
      setTimeout(() => {
        // this.templateName = templateName;
        this.reminder_msg_content = message;
        this.calendar_form.reminder_msg['template_name'] = templateName;
        this.calendar_form.reminder_msg['message'] = message;
      }, 100);
    },
  },
};
</script>
<style>
.mr-1 {
  margin-right: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.filters-wrap {
  padding: var(--space-normal);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  margin-bottom: var(--space-normal);
}

.filter-actions {
  margin-top: var(--space-normal);
}

.formulate-input {
  width: 100%;
}
</style>
