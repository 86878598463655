/* global axios */
import ApiClient from './ApiClient';

class AccountAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  createAccount(data) {
    return axios.post(`${this.apiVersion}/accounts`, data);
  }

  updateToken(data) {
    return axios.post(`${this.apiVersion}/accounts/update_token`, data);
  }

  updateAccount(payload) {
    return axios.patch(`${this.url}`,payload);
  }
  fetchAccountInbox() {
    return axios.get(`${this.url}fetch_plan`);
  }

  fetchTopUpDetails() {
    return axios.get(`${this.url}fetch_top_up`);
  }

  fetchUsdPrice() {
    return axios.get(`${this.url}fetch_usd_price`);
  }

  fetchBasePath() {
    return axios.get(`${this.url}fetch_base_path`);
  }

  fetchContactStatus() {
    return axios.get(`${this.url}fetch_contact_status`);
  }

  fetchCommunalInboxes() {
    return axios.get(`${this.url}fetch_communal_inboxes`);
  }

  fetchSubscriptionPlans() {
    return axios.get(`${this.url}fetch_subscription_plans?type=all`);
  }

  createTemplateMsg(data) {
    return axios.post(`${this.url}create_template_message`, data);
  }
  setCustomAttributeToAdminContact(data) {
    return axios.post(`${this.url}update_business_sector`, data);
  }

  resendVerificationCode() {
    return axios.get(`${this.apiVersion}/profile/resend_confirmation`);
  }

  getCreditExpiredReport(data) {
    const params = new URLSearchParams(data).toString();
    return axios.get(`${this.url}credit_expired/transactions?${params}`);
  }

  fetchDefaultOrderTemplateMsg(data) {
    return axios.get(`${this.url}fetch_order_templates`);
  }



}

export default new AccountAPI();
