/* global axios */
import ApiClient from './ApiClient';

class DashboardAPI extends ApiClient {
  constructor() {
    super('dashboard', { accountScoped: true });
  }

  // get dashboard stats  /accounts/:account_id/dashboard
  getStats({start_date,end_date}){
    // pass start and end date
    return axios.get(`${this.url}`,{
      params:{start_date,end_date}
    });
  }

}

export default new DashboardAPI();
