import types from "../mutation-types";
import LevelsApi from '../../api/levelsApi'

export const state = {
    levels: [],
    uiFlags: {
        isFetching: false,
        isCreating: false,
        isDeleting: false,
        isUpdating: false,
    },
};

// list of getters
const getters = {
    getUIFlags(_state){
        return _state.uiFlags;
    },
    getLevels(_state){
        return _state.levels;
    },
}


// actions list
const actions = {
    fetchLevels: async function({commit},queryParams){
        commit(types.SET_LEVELS_UI_FLAG,{isFetching: true});
        try {
            const {data} = await LevelsApi.getLevels();
            console.log(data);
            if (data.data) {
                commit(types.SET_LEVELS, data.data);
            }
            commit(types.SET_LEVELS_UI_FLAG,{isFetching: false});
        } catch (error) {
            commit(types.SET_LEVELS_UI_FLAG,{isFetching: false});
        }
    },

}


// list of mutations
const mutations = {
    // mutate UI flags
    [types.SET_LEVELS_UI_FLAG](_state, data) {
        _state.uiFlags = {
          ..._state.uiFlags,
          ...data,
        };
    },
    [types.SET_LEVELS](_state, data) {
        _state.levels = data;
    },


}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}