<template>
  <div>
    <div class="express_container">
      <table>
        <tr class="flow-header">
          <td class="center">
            <h1 class="name">
              {{ $t('FLOW.BOT_ID') }}
            </h1>
          </td>
          <td class="center">
            <h1 class="name">
              {{ $t('FLOW.NAME') }}
            </h1>
          </td>
          <td class="center">
            <h1 class="name flex">
              {{ $t('FLOW.MODIFIED') }}
            </h1>
          </td>
          <!-- <td class="center">
            <h1 class="name">
              {{ $t('FLOW.ACTIONS') }}
            </h1>
          </td> -->
        </tr>

        <tr
          v-for="(flowItem, index) in expressBots"
          :key="index"
          class="flow-box"
          @click="itemClick(flowItem)"
        >
          <td class="left">
            <h1 class="name">
              {{ flowItem.bot_id}}
            </h1>
          </td>
          <td class="left">
            <h1 class="name">
              {{ flowItem.name}}
            </h1>
          </td>
          <td class="center">
            <h1 class="date">
              {{
                moment(flowItem.updated_at)
                  .local()
                  .fromNow()
              }}
            </h1>
          </td>

          <!-- <td class="right">
            <woot-button
              variant="smooth"
              size="small"
              color-scheme="secondary"
              icon="delete"
              class-names="grey-btn btn-width-auto"
              @click="deleteFlow(flowItem)"
            >
            </woot-button>
          </td> -->
        </tr>



      </table>
    </div>
    <ExpressModadlWrap />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import ExpressModadlWrap from "../../../botExpress/popups/ExpressModadlWrap.vue"


export default {
  components: {
    ExpressModadlWrap,
  },
  data(){
    return{
      moment: moment,
    }
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlagsExpressBot',
      expressBots: 'campaigns/getExpressBots',
    }),
  },
  mounted(){
    this.$store.dispatch('campaigns/fetchExpressBots');
  },
  methods:{
    itemClick(item){
      // set in query param
      const { open_express_bot, ...restQuery } = this.$route.query;
        this.$router.replace({
            query: {
              ...this.$route.query,
              open_express_bot: true
            }
        });
    },
  }

}
</script>

<style lang="scss" scoped>
.express_container{
    width: 75%;
    margin: auto;
    margin-top: 50px;
}
</style>