<template>
  <div>
    <label
      v-if="isSuperAdmin"
      class="custom-checkbox-container"
      style="
        position: absolute;
        right: 12rem;
        top: 7.5rem;"
    >
      {{ $t('FLOW.PLACEHOLDERS.TEMPLATE') }}
      <input
        id="waitForUserMessage"
        v-model="useAsTemplate"
        type="checkbox"
        @change="onUpdateTemplateCheckbox"
      />
      <span class="checkmark"></span>
    </label>

    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top save-button"
      icon="arrow-download"
      :disabled="isExporting"
      :is-loading="isExporting"
      @click="exportIntoBotpress"
    >{{ isExporting ? $t('FLOW.SAVING') : $t('FLOW.SAVE') }}</woot-button>
    <!-- <woot-loading-state v-if="showFlowList" message="Fetching Bots" /> -->

    <woot-loading-state v-if="isLoading" message="Fetching bot" />

    <div v-show="showDiagram || true">
      <VueDiagramEditor
        ref="diagram"
        :node-color="nodeColor"
        :node-pulsable="nodePulsable"
        :node-deletable="nodeDeletable"
        :botid="this.$route.params.flowid"
        @clicked-node="clickedNode"
        @add-btn-clicked="addClicked"
      ></VueDiagramEditor>

      <woot-modal
        :show.sync="showAddPopup"
        :on-close="currentNode && currentNode.data.type =='question'  ? closeQuestionModal : hideAddPopup"
        size="medium"
        custom-class="width"
      >
        <div v-if="currentNode && currentNode.data.type =='question' ">
          <question-node
            ref="questionNodeAddModal"
            :node="currentNode"
            :dref="this.$refs.diagram"
            :botid="this.$route.params.flowid"
            :profiles="profiles"
            @on-close="hideAddPopup"
            @all-done="editDone"
          />
        </div>
        <div v-if="currentNode && currentNode.data.type =='stripe' ">
          <stripe-node
            :node="currentNode"
            :dref="this.$refs.diagram"
            :botid="this.$route.params.flowid"
            :profiles="profiles"
            @on-close="hideAddPopup"
            @all-done="editDone"
          />
        </div>
        <edit-node
          v-else
          :node="currentNode"
          :dref="this.$refs.diagram"
          :botid="this.$route.params.flowid"
          :profiles="profiles"
          :currentUser="currentUser"

        />
        <div
          v-if="currentNode && currentNode.data.type !='question' && currentNode.data.type !='stripe'"
          class="modal-footer"
          style="padding-left: 32px; padding-right: 32px;"
        >
          <woot-button :disabled="!allGood" @click.prevent="editDone">{{ $t('FLOW.DONE') }}</woot-button>
          <woot-button variant="clear" @click.prevent="hideAddPopup">{{ $t('FLOW.CANCEL') }}</woot-button>
        </div>
      </woot-modal>

      <a href="#" class="float" @click="toggleAddMenu">
        <img
          src="~dashboard/assets/images/flow-new/add-icon.png"
          :class="[showAddMenu == true ? 'image-float-variant' : 'image-float']"
          width="32"
        />
      </a>

      <div v-show="showAddMenu" class="float-box">
        <h4 class="title">{{ $t('FLOW.ADD_NODE') }}</h4>
        <div class="item" style="background-color:white" @click="addContentNode()">
          <img src="~dashboard/assets/images/flow-new/menu-icon-content.png" />
          <h6 class="text">{{ $t('FLOW.NODE_ADD_CONTENT') }}</h6>
          <span></span>
        </div>
        <br />
        <div class="item" style="background-color:#EDFFC0" @click="addConditionNode()">
          <img src="~dashboard/assets/images/flow-new/menu-icon-condition.png" />
          <h6 class="text">{{ $t('FLOW.NODE_ADD_CONDITION') }}</h6>
          <span></span>
        </div>
        <br />
        <div class="item" style="background-color:#B2DBFF" @click="addActionNode()">
          <img src="~dashboard/assets/images/flow-new/menu-icon-action.png" />
          <h6 class="text">{{ $t('FLOW.NODE_ADD_ACTION') }}</h6>
          <span></span>
        </div>
        <br />
        <div class="item" style="background-color:#FFD68A" @click="addCallAPINode()">
          <img src="~dashboard/assets/images/flow-new/api-call.svg" width="16px" />
          <h6 class="text">{{ $t('FLOW.NODE_ADD_CALL_API') }}</h6>
          <span></span>
        </div>
        <br />
        <div class="item" style="background-color:#E8A7D5" @click="addQuestionNode()">
          <img src="~dashboard/assets/images/flow-new/menu-icon-content.png" />
          <h6 class="text">{{ $t('FLOW.QUESTION') }}</h6>
          <span></span>
        </div>
        <div class="item" style="background-color:#BFE3B4" @click="addStripeNode()">
          <img src="~dashboard/assets/images/flow-new/menu-icon-content.png" />
          <h6 class="text">{{ $t('FLOW.STRIPE') }}</h6>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import VueDiagramEditor from "../vue-diagram-editor/src/index";
// import "vue-diagram-editor/dist/vue-diagram-editor.css";
import VueDiagramEditor, { Node } from '../vue-diagram-editor/src';
import EditNode from './modals/EditNode.vue';
import './style.css';
import alertMixin from 'shared/mixins/alertMixin';
import FlowApi from '../FlowApi';
import { mapGetters } from 'vuex';
import PMConnectAPI from '../PMConnectAPI';
import { ulid } from 'ulid';
import QuestionNode from './modals/QuestionNode.vue';
import StripeNode from './modals/StripeNode.vue';

export default {
  name: 'VueDiagram',
  components: {
    VueDiagramEditor,
    EditNode,
    QuestionNode,
    StripeNode
},
  mixins: [alertMixin],
  data: () => ({
    Node,
    allGood: true,
    showFlowList: false,
    show: true,
    showAddPopup: false,
    currentNode: null,
    showAddMenu: false,
    isExporting: false,
    isLoading: false,
    showDiagram: false,
    useAsTemplate: false,
    nodes: {
      'node-start': {
        id: 'node-start',
        title: 'Start',
        size: {
          width: 150,
          height: 100,
        },

        data: {
          color: '#f3f3f3',
          deletable: false,
          type: 'entry',
        },

        portsOut: {
          default: '',
        },
        coordinates: {
          x: 50,
          y: 75,
        },
      },
    },
    links: {},
    userId: null,
    isSuperAdmin: false,
    showPopup: false,
    popX: 0,
    popY: 0,
    profiles: [],
  }),

  watch: {
    $route: async function(to) {
      let path = to.fullPath;
      if (path.includes('/campaigns/flows/edit')) {
        this.getBot();
        this.getBrandProfiles();
      }
    },
    currentUserId(newCurrentUserId, prevCurrentUserId) {
      if (prevCurrentUserId !== newCurrentUserId) {
        this.userId = newCurrentUserId;
        this.getBot();
      }
    },
    async currentUser(newCurrentUser, prevCurrentUser) {
      if (newCurrentUser !== prevCurrentUser && !this.profiles) {
        this.getBrandProfiles();
      }
    },
  },

  async created() {
    let path = this.$route.path;

    if (path.includes('/campaigns/flows/edit')) {
      this.getBot();
    }

    if (this.currentUserId) {
      this.userId = this.currentUserId;
      this.getBot();
    }
  },

  computed: {
    ...mapGetters({
      currentUserId: 'getCurrentUserID',
      currentUser: 'getCurrentUser',
    }),
  },

  methods: {
    closeQuestionModal() {
      this.$refs.questionNodeAddModal.checkValidation();
    },
    async onUpdateTemplateCheckbox() {
      let botId = this.$route.params.flowid;
      this.isExporting = true;
      let response = await FlowApi.exportFlow(botId, {}, this.useAsTemplate);
      if (response.status) {
        this.showAlert('Bot Updated');
      } else {
        this.showAlert(response.message);
      }
      this.isExporting = false;
    },

    init() {
      this.$refs.diagram.setModel({
        nodes: this.nodes,
        links: this.links,
      });
    },

    async getBot() {
      if (!this.userId) {
        return;
      }
      this.isLoading = true;
      let response = await FlowApi.getFlowById(
        this.$route.params.flowid,
        this.userId
      );

      if (response.status === 'ok') {
        let data = response.data;

        let groupData = _.groupBy(Object.values(data.nodes), 'data.displayId');

        let tempNodes = {};
        for (let displayId in groupData) {
          if (displayId != 'undefined') {
            let nodes = this.getQuestionNode(displayId, groupData[displayId]);
            tempNodes[nodes.id] = nodes;
          }
        }

        groupData['undefined'].forEach((node, index) => {
          tempNodes[node.id] = node;
        });

        let questionNodeIDs = [];
        for (const groupKey in groupData) {
          if (groupKey != 'undefined') {
            questionNodeIDs.push(groupData[groupKey][0].id.split('-')[1]);
          }
        }

        const outputLinks = {};

        for (const linkKey in data.links) {
          const link = data.links[linkKey];
          if (questionNodeIDs.includes(link.end_id.split('-')[1])) {
            let commonPostFix = link.end_id.split('-')[1];
            if (link.end_id.split('-')[0] == 'condition') {
              outputLinks[link.id] = {
                ...link,
                end_id: `question-${commonPostFix}`,
                end_port: `port-in-question-${commonPostFix}`,
              };
            }
          } else if (questionNodeIDs.includes(link.start_id.split('-')[1])) {
            let commonPostFix = link.start_id.split('-')[1];
            if (link.start_id.split('-')[0] == 'action') {
              outputLinks[link.id] = {
                ...link,
                start_id: `question-${commonPostFix}`,
                start_port: `port-out-question-${commonPostFix}`,
              };
            }
          } else {
            outputLinks[link.id] = { ...link };
          }
        }

        this.$refs.diagram.setModel({
          // nodes:response.data.nodes,
          // links: data.links,
          nodes: tempNodes,
          links: outputLinks,
        });
        this.useAsTemplate = data.template;
        this.isSuperAdmin = data.isSuperAdmin;
        this.showDiagram = true;
      } else {
        this.showAlert(response.message);
        this.showDiagram = false;
      }
      this.isLoading = false;
    },

    async exportIntoBotpress() {
      let botId = this.$route.params.flowid;

      let data = this.$refs.diagram.getDiagramData();

      let dataString = JSON.stringify(data);

      let dataParsed = JSON.parse(dataString);

      this.isExporting = true;

      let isDataValidateForBot = true;

      // split combine node
      for (let nodeKey in dataParsed.nodes) {
        let node = dataParsed.nodes[nodeKey];
        if (node.data.type == 'question') {
          let commonPostFix = nodeKey.split('-')[1];
          if (
            dataParsed.nodes[nodeKey].data.subNodes[
              `condition-${commonPostFix}`
            ].data.contents[1].customAttr.length === 0 &&
            dataParsed.nodes[nodeKey].data.subNodes[`content-${commonPostFix}`]
              .data.contents[0].title.length === 0 &&
            dataParsed.nodes[nodeKey].data.subNodes[`content-${commonPostFix}`]
              .data.contents[0].subtitle.length === 0
          ) {
            isDataValidateForBot = false;
          }
          for (let subNodeKey in node.data.subNodes) {
            let subNode = node.data.subNodes[subNodeKey];

            if (typeof subNode != 'function') {
              dataParsed.nodes[subNode.id] = subNode;
              dataParsed.nodes[subNode.id].coordinates.x = node.coordinates.x;
              dataParsed.nodes[subNode.id].coordinates.y = node.coordinates.y;
            }
          }
          delete dataParsed.nodes[node.id];
        }
      }

      if (!isDataValidateForBot) {
        this.isExporting = false;
        this.showAlert(
          'Data is not validate for bot Please check all question node'
        );
        return;
      }
      const inputLinks = data.links;

      const outputLinks = {};

      for (const linkKey of Object.keys(inputLinks)) {
        const link = inputLinks[linkKey];
        if (link.end_id.startsWith('question-')) {
          let commonPostFix = link.end_id.split('-')[1];
          data.nodes[link.end_id].data.subNodes[`condition-${commonPostFix}`];
          // update existing link
          outputLinks[link.id] = {
            ...link,
            end_id: `condition-${commonPostFix}`,
            end_port: `port-in-condition-${commonPostFix}`,
          };

          let uniqId = ulid();
          if (
            !Object.values(outputLinks).find(
              ({ start_id, end_id }) =>
                start_id === `condition-${commonPostFix}` &&
                end_id === `content-${commonPostFix}`
            )
          ) {
            // add content link
            outputLinks[uniqId] = {
              id: uniqId,
              start_id: `condition-${commonPostFix}`,
              start_port: `condition-outport-${commonPostFix}`,
              end_id: `content-${commonPostFix}`,
              end_port: `port-in-content-${commonPostFix}`,
              breakPoints: [],
            };

            uniqId = ulid();
            outputLinks[uniqId] = {
              id: uniqId,
              start_id: `condition-${commonPostFix}`,
              start_port: `port-out-condition-otherwise-${commonPostFix}`,
              end_id: `action-${commonPostFix}`,
              end_port: `port-in-action-${commonPostFix}`,
              breakPoints: [],
            };
          }

          if (
            !Object.values(outputLinks).find(
              ({ start_id, end_id }) =>
                start_id === `content-${commonPostFix}` &&
                end_id === `action-${commonPostFix}`
            )
          ) {
            // add action link
            uniqId = ulid();
            outputLinks[uniqId] = {
              id: uniqId,
              start_id: `content-${commonPostFix}`,
              start_port: `port-out-content-${commonPostFix}`,
              end_id: `action-${commonPostFix}`,
              end_port: `port-in-action-${commonPostFix}`,
              breakPoints: [],
            };
          }
        } else if (link.start_id.startsWith('question-')) {
          let commonPostFix = link.start_id.split('-')[1];
          outputLinks[link.id] = {
            ...link,
            start_id: `action-${commonPostFix}`,
            start_port: `port-out-action-${commonPostFix}`,
          };
        } else {
          outputLinks[link.id] = { ...link };
        }
      }

      dataParsed.links = outputLinks;

      let response = await FlowApi.exportFlow(botId, dataParsed);

      if (response.status) {
        this.showAlert('Bot Exported');
      } else {
        this.showAlert(response.message);
      }

      this.isExporting = false;
    },
    editFlow() {
      this.showFlowList = false;
      this.init();
    },
    format(node) {
      return JSON.stringify(node, null, 2);
    },
    nodeColor(node) {
      if (node.data.type === 'action') {
        return '#333';
      }
      return '#fff';
    },

    async editDone() {
      this.showAddPopup = false;
    },

    nodeDeletable(node) {
      return node.data.deletable ? node.data.deletable : true;
    },

    changeName() {
      this.showAddPopup = false;
    },
    toggleAddMenu() {
      this.showAddMenu = !this.showAddMenu;
    },
    addContentNode(x = 0, y = 0) {
      var result = '';
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (let i = 0; i < 5; i += 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      let pIn = 'port-in-content-' + result;
      let pOut = 'port-out-content-' + result;

      let newNode = {
        id: 'content-' + result,
        title: 'content-' + result,
        size: {
          width: 160,
          height: 120,
        },

        data: {
          color: '#f3f3f3',
          type: 'content',
          contents: [],
          customName: 'content-' + result,
        },

        coordinates: {
          x: x,
          y: y,
        },
      };

      let tempIn = {};
      tempIn[pIn] = '';

      let tempOut = {};
      tempOut[pOut] = '';

      newNode.portsIn = tempIn;
      newNode.portsOut = tempOut;

      this.$refs.diagram.addNode(newNode);

      this.showAddMenu = false;
    },
    addConditionNode(x = 0, y = 0) {
      var result = '';
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (let i = 0; i < 5; i += 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      let pIn = 'port-in-condition-' + result;
      let pOut = 'port-out-condition-otherwise-' + result;

      let newNode = {
        id: 'condition-' + result,
        title: 'condition-' + result,

        size: {
          width: 160,
          height: 120,
        },

        data: {
          color: '#f3f3f3',
          type: 'condition',
          contents: [
            {
              type: 'otherwise-condition',
              condId: pOut,
            },
          ],
          customName: 'condition-' + result,
        },

        portsOut: {
          pOut: '',
        },
        coordinates: {
          x: x,
          y: y,
        },
      };

      let tempIn = {};
      tempIn[pIn] = '';
      let tempOut = {};
      tempOut[pOut] = '';

      newNode.portsIn = tempIn;
      newNode.portsOut = tempOut;
      this.$refs.diagram.addNode(newNode);

      this.showAddMenu = false;
    },

    addActionNode(x = 0, y = 0) {
      var result = '';
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (let i = 0; i < 5; i += 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      let pIn = 'port-in-action-' + result;
      let pOut = 'port-out-action-' + result;

      let newNode = {
        id: 'action-' + result,
        title: 'action-' + result,
        size: {
          width: 160,
          height: 120,
        },

        data: {
          color: '#f3f3f3',
          type: 'action',
          contents: [],
          customName: 'action-' + result,
        },

        coordinates: {
          x: x,
          y: y,
        },
      };

      let tempIn = {};
      tempIn[pIn] = '';

      let tempOut = {};
      tempOut[pOut] = '';

      newNode.portsIn = tempIn;
      newNode.portsOut = tempOut;

      this.$refs.diagram.addNode(newNode);

      this.showAddMenu = false;
    },

    getQuestionNode(displayId, subNodesArr) {
      let result = subNodesArr[0].id.split('-')[1];
      const subNodes = {};

      subNodesArr.forEach((node, index) => {
        subNodes[node.id] = node;
      });

      let pIn = 'port-in-question-' + result;
      let pOut = 'port-out-question-' + result;

      return {
        id: 'question-' + result,
        title: 'question-' + result,

        size: {
          width: 160,
          height: 120,
        },

        data: {
          color: '#f3f3f3',
          type: 'question',
          contents: [],
          attribute: 'test',

          customName: 'question-' + result,
          displayId: displayId,
          subNodes,
        },

        portsIn: { [pIn]: '' },
        portsOut: { [pOut]: '' },
        coordinates: {
          x: subNodesArr[0].coordinates.x,
          y: subNodesArr[0].coordinates.y,
        },
      };
    },

    addQuestionNode(x = 0, y = 0) {
      let displayId = ulid();
      var result = '';
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (let i = 0; i < 5; i += 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      let pIn = 'port-in-question-' + result;
      let pOut = 'port-out-question-' + result;

      let newNode = {
        id: 'question-' + result,
        title: 'question-' + result,

        size: {
          width: 160,
          height: 120,
        },

        data: {
          color: '#f3f3f3',
          type: 'question',
          contents: [],
          attribute: 'test',
          customName: 'question-' + result,
          displayId: displayId,
          subNodes: {
            ['condition-' + result]: {
              id: 'condition-' + result,
              title: 'condition-' + result,

              size: {
                width: 160,
                height: 120,
              },

              data: {
                color: '#f3f3f3',
                type: 'condition',
                contents: [
                  {
                    type: 'otherwise-condition',
                    condId: `port-out-condition-otherwise-${result}`,
                  },
                  {
                    type: 'condition-contact',
                    operator: {
                      id: 'op-x-null',
                      name: 'is Null',
                    },
                    operandB: {
                      id: 'op-text',
                      name: 'Text',
                    },
                    attrType: {
                      id: 'custom',
                      name: 'Custom',
                    },
                    attr: {
                      id: 'name',
                      name: 'Name',
                    },
                    value: '',
                    name: '',
                    condId: `condition-outport-${result}`,
                    customAttr: '',
                    paramName: '',
                    propertyName: '',
                  },
                ],
                customName: 'condition-' + result,
                displayId: displayId,
              },

              coordinates: {
                x: x,
                y: y,
              },
              portsIn: { ['port-in-condition-' + result]: '' },
              portsOut: {
                ['condition-outport-' + result]: '',
                ['port-out-condition-otherwise-' + result]: '',
              },
            },
            ['content-' + result]: {
              id: 'content-' + result,
              title: 'content-' + result,
              size: {
                width: 160,
                height: 120,
              },

              data: {
                color: '#f3f3f3',
                type: 'content',
                contents: [
                  {
                    value: '',
                    type: 'card',
                    title: '',
                    subtitle: '',
                    file: '',
                    actionButtons: [],
                  },
                ],
                customName: 'content-' + result,
                waitForUserMessage: true,
                displayId: displayId,
              },

              coordinates: {
                x: x,
                y: y,
              },

              portsIn: {
                ['port-in-content-' + result]: '',
              },
              portsOut: {
                ['port-out-content-' + result]: '',
              },
            },
            ['action-' + result]: {
              id: 'action-' + result,
              title: 'action-' + result,
              size: {
                width: 160,
                height: 120,
              },

              data: {
                color: '#f3f3f3',
                type: 'action',
                contents: [
                  {
                    type: 'action-contact',
                    name: '',
                    attr: {
                      id: 'name',
                      name: 'Name',
                    },
                    value: {
                      id: 'response',
                      name: 'Response',
                    },
                    attrType: {
                      id: 'custom',
                      name: 'Custom',
                    },
                    name: '',
                    customAttr: '',
                    custom: '',
                    paramName: '',
                  },
                ],
                customName: 'action-' + result,
                endFlowAction: false,
                displayId: displayId,
              },

              coordinates: {
                x: x,
                y: y,
              },
              portsIn: {
                ['port-in-action-' + result]: '',
              },
              portsOut: {
                ['port-out-action-' + result]: '',
              },
            },
          },
        },

        portsIn: { [pIn]: '' },
        portsOut: { [pOut]: '' },
        coordinates: {
          x: x,
          y: y,
        },
      };

      this.$refs.diagram.addNode(newNode);

      this.showAddMenu = false;
    },

    addStripeNode(x = 0, y = 0) {
      var result = '';
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (let i = 0; i < 5; i += 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      let pIn = 'port-in-stripe-' + result;
      let pOut = 'port-out-stripe-' + result;

      let newNode = {
        id: 'stripe-' + result,
        title: 'stripe-' + result,
        size: {
          width: 160,
          height: 120,
        },

        data: {
          color: '#f3f3f3',
          type: 'stripe',
          contents: [],
          customName: 'stripe-' + result,
        },

        coordinates: {
          x: x,
          y: y,
        },
      };

      let tempIn = {};
      tempIn[pIn] = '';

      let tempOut = {};
      tempOut[pOut] = '';

      newNode.portsIn = tempIn;
      newNode.portsOut = tempOut;

      this.$refs.diagram.addNode(newNode);

      this.showAddMenu = false;
    },

    addCallAPINode(x = 0, y = 0) {
      var result = '';
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (let i = 0; i < 5; i += 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      let pIn = 'port-in-call-api-' + result;
      const pOut1 = 'call-api-success-' + result;
      const pOut2 = 'call-api-failure-' + result;
      let newNode = {
        id: 'call-api-' + result,
        title: 'call-api-' + result,

        size: {
          width: 160,
          height: 120,
        },

        data: {
          color: '#f3f3f3',
          type: 'call-api',
          skillData: {
            method: 'get',
            memory: 'temp',
            randomId: '',
            body: null,
            headers: null,
            url: null,
            variable: 'response',
            invalidJson: false,
          },
          contents: [
            {
              type: 'call-api-success',
              condId: pOut1,
            },
            {
              type: 'call-api-failure',
              condId: pOut1,
            },
          ],
          customName: 'call-api-' + result,
        },

        portsOut: {},
        coordinates: {
          x: x,
          y: y,
        },
      };

      let tempOut = {};
      tempOut[pOut1] = '';
      tempOut[pOut2] = '';

      newNode.portsOut = tempOut;

      let tempIn = {};
      tempIn[pIn] = '';

      newNode.portsIn = tempIn;

      this.$refs.diagram.addNode(newNode);

      this.showAddMenu = false;
    },

    clickedNode(node) {
      this.getBrandProfiles();
      this.currentNode = node;
      this.showAddPopup = true;
    },

    addClicked(arg) {
      let deltaX = 150;
      let deltaY = 150;

      if (arg.type === 'content') {
        this.addContentNode(arg.node.x + deltaX, arg.node.y + deltaY);
      }
      if (arg.type === 'condition') {
        this.addConditionNode(arg.node.x + deltaX, arg.node.y + deltaY);
      }
      if (arg.type === 'action') {
        this.addActionNode(arg.node.x + deltaX, arg.node.y + deltaY);
      }
      if (arg.type === 'call-api') {
        this.addCallAPINode(arg.node.x + deltaX, arg.node.y + deltaY);
      }
      // if (arg.type === 'combine') {
      //   this.addCombineNode(arg.node.x + deltaX, arg.node.y + deltaY);
      // }
      if (arg.type === 'question') {
        this.addQuestionNode(arg.node.x + deltaX, arg.node.y + deltaY);
      }
      if (arg.type === 'stripe') {
        this.addStripeNode(arg.node.x + deltaX, arg.node.y + deltaY);
      }
    },

    nodePulsable() {
      return false;
    },

    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    async getBrandProfiles() {
      if (
        this.currentUser &&
        Array.isArray(this.profiles) &&
        this.profiles.length === 0
      ) {
        this.accountId = this.currentUser.account_id;
        let profiles = await FlowApi.getIntegratedBrandProfiles(
          this.currentUser.account_id
        );

        if (Array.isArray(profiles.data) && profiles.data.length > 0) {
          let brandProfilesResponse = await PMConnectAPI.getBrandProfileList();
          let mappedProfiles = {};
          brandProfilesResponse.forEach(responseProfile => {
            mappedProfiles[responseProfile.id] = responseProfile.brand_name;
          });
          const allProfiles = profiles?.data;
          let preparedProfiles = [];
          for (let i = 0; i < allProfiles.length; i += 1) {
            let temp = {};
            const profileId = allProfiles[i].settings?.brand_profile_id;
            temp.brand_id = profileId;
            temp.api_key = allProfiles[i].settings?.peasy_api_key;
            temp.brand_name =
              mappedProfiles[`${profileId}`] || 'Brand Profile ' + profileId;

            preparedProfiles.push(temp);
          }
          this.profiles = preparedProfiles;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
