<template>
  <section class="leaderboard">
    <h2 class="board_title">{{$t('D_BOARD.LEADER_BOARD.TITLE')}}</h2>
    <h3 class="board_title title_secondary">{{$t('D_BOARD.LEADER_BOARD.SECONDARY_TITLE')}}</h3>
    <p class="description">
      {{score.level_name}}
    </p>
    <ul class="leaders">
      <li v-for="(item,index) in leaderboard" class="leader" :key="item.id">
        <div class="rank">
          <div :class="index>2?'rank_circle_bg':''">
            <span :class="index < 3?'top_rank':'normal_rank'">{{index + 1}}</span>
            <svg v-if="index < 3" class="badge_bg" width="48" height="59" viewBox="0 0 48 59" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_1845_15519)">
              <path :d="badgePath" :fill="getBadgeColor(index)"/>
              </g>
              <defs>
              <filter id="filter0_d_1845_15519" x="0.25" y="0.742676" width="47.5" height="57.509" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset/>
              <feGaussianBlur stdDeviation="5"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.996078 0 0 0 0 0.741176 0 0 0 0 0 0 0 0 0.5 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1845_15519"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1845_15519" result="shape"/>
              </filter>
              </defs>
              </svg>
          </div>
        </div>
        <div class="company">
          <h5>{{item.company_name}}</h5>
          <div  class="info">
            <div>{{item.total_gmv}} MYR</div>
            <div v-if="item.average_speed > 0" class="speed">
              <span>
                <fluent-icon icon="electricity" />
              </span>
              {{item.average_speed}}
              {{$t('D_BOARD.LEADER_BOARD.SPEED')}}
              </div>
          </div>
        </div>
        <div class="percent">{{item.percentage_completed}}%</div>
      </li>
    </ul>
    <woot-loading-state
      v-if="uiFlags.isFetching"
      :message="$t('D_BOARD.LEADER_BOARD.LOADING')"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

const badgePathCode = "M37.75 24.5008C37.7514 22.1837 37.1671 19.9037 36.0515 17.8728C34.9359 15.8418 33.3251 14.1257 31.3688 12.8839C29.4125 11.6421 27.174 10.9148 24.8614 10.7697C22.5488 10.6245 20.2369 11.0662 18.1407 12.0536C16.0444 13.041 14.2317 14.5422 12.8709 16.4177C11.5101 18.2932 10.6453 20.4822 10.3569 22.7814C10.0685 25.0806 10.3659 27.4153 11.2213 29.5688C12.0767 31.7223 13.4626 33.6247 15.25 35.0993V47.0008C15.2498 47.214 15.3042 47.4237 15.4079 47.6099C15.5117 47.7962 15.6613 47.9528 15.8426 48.0649C16.0239 48.177 16.2309 48.2409 16.4439 48.2505C16.6569 48.26 16.8687 48.215 17.0594 48.1196L24 44.6571L30.9422 48.1274C31.1162 48.2107 31.3071 48.2529 31.5 48.2508C31.8315 48.2508 32.1495 48.1191 32.3839 47.8847C32.6183 47.6503 32.75 47.3324 32.75 47.0008V35.0993C34.314 33.8112 35.5734 32.1929 36.4378 30.3604C37.3023 28.528 37.7504 26.5269 37.75 24.5008ZM12.75 24.5008C12.75 22.2758 13.4098 20.1007 14.646 18.2507C15.8821 16.4006 17.6391 14.9587 19.6948 14.1072C21.7505 13.2557 24.0125 13.0329 26.1948 13.467C28.3771 13.9011 30.3816 14.9725 31.955 16.5459C33.5283 18.1192 34.5998 20.1238 35.0338 22.3061C35.4679 24.4884 35.2451 26.7504 34.3936 28.806C33.5422 30.8617 32.1002 32.6187 30.2502 33.8549C28.4001 35.091 26.225 35.7508 24 35.7508C21.0173 35.7475 18.1578 34.5612 16.0487 32.4521C13.9396 30.3431 12.7533 27.4835 12.75 24.5008ZM15.25 24.5008C15.25 22.7703 15.7632 21.0785 16.7246 19.6396C17.6861 18.2007 19.0527 17.0792 20.6515 16.4169C22.2504 15.7546 24.0097 15.5813 25.707 15.919C27.4044 16.2566 28.9635 17.0899 30.1872 18.3137C31.4109 19.5374 32.2443 21.0965 32.5819 22.7938C32.9195 24.4911 32.7462 26.2505 32.0839 27.8493C31.4217 29.4482 30.3002 30.8147 28.8612 31.7762C27.4223 32.7377 25.7306 33.2508 24 33.2508C21.6801 33.2484 19.456 32.3257 17.8156 30.6853C16.1752 29.0449 15.2525 26.8207 15.25 24.5008Z";

export default {
    computed: {
      ...mapGetters({
        leaderboard: 'score/getLeaderboard',
        uiFlags: 'score/getUIFlagsLeaderboard',
        score: 'score/getScore',
      }),
    },
    data(){
      return {
        badgePath: badgePathCode,
      }
    },
    methods:{
      getBadgeColor(index){
        switch (index) {
          case 0:
            return '#FEBD00';
          case 1:
            return '#BFBFBF';
          case 2:
            return '#CE7330';

          default:
            return ""
        }
      }
    },
}
</script>

<style lang="scss" scoped>
.leaderboard{
  padding: 16px 20px;
  border-radius: 16px;
  border: 1px solid #EEEEEE;
  color: #1F1F1F;

  .board_title{
    font-weight: 700;
    font-size: 24px;
  }
  .title_secondary{
    margin-top: 16px;
    font-size: 20px;
    color: #000;
  }
  .description{
    font-size: 16px;
    font-weight: 500;
  }
  .leaders{
    margin: 12px 0 0 0;
    list-style: none;
    max-height: 326px;
    overflow-y: auto;
    overflow-x: hidden;

    .leader{
      display: grid;
      grid-template-columns: 40px 1fr 40px;
      gap: 8px;
      padding: 4px 0;

      .rank{
        display: flex;
        justify-content: center;
        align-items: center;
        div{
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          span{
            position: absolute;
          }
          .top_rank{
            top: 10px;
            font-size: 10px;
            font-weight: 900;
            color: white;
          }
          .normal_rank{
            font-size: 20px;
            font-weight: 700;
            color: #8D25F6;
          }
        }
        .rank_circle_bg{
          background: #EBFCC0;
        }
      }
      .company{
        h5{
          font-weight: 600;
          font-size: 14px;
          color: #000;
        }
        .info{
          display: flex;
          gap: 8px;

          font-weight: 500;
          font-size: 14px;
          color: #858585;
          .speed{
            display: flex;
            span{
              svg{
                width: 18px;
                height: 18px;
                color: #FFAA00;
              }
            }
          }
        }
      }
      .percent{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        color: #000;
      }
    }
  }
}
</style>