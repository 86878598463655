<template>
  <div class="column">
    <div class="d-flex">
      <select class="business_hours_select" v-model="day">
        <option
          v-for="weekday in allowdWeekDaysOptions"
          :key="weekday.id"
          :value="weekday.id"
        >
          {{ weekday.label }}
        </option>
      </select>
      <label>From</label>
      <select
        v-model="start_hour"
        class="business_hours_select"
        @change="onChangeStartHour($event.target.value)"
      >
        <option v-for="time in times" :key="time.id" :value="time.id">
        <!-- <option v-for="time in filteredStartHourTimes" :key="time.id" :value="time.id"> -->
          {{ time.label }}
        </option>
      </select>
      <label>To</label>
      <select v-model="end_hour" class="business_hours_select">
        <option v-for="time in endHourTimes" :key="time.id" :value="time.id">
        <!-- <option v-for="time in filteredEndHourTimes" :key="time.id" :value="time.id"> -->
          {{ time.label }}
        </option>
      </select>
      <label>Hrs</label>
      <woot-button
        v-tooltip.top="$t('AUTOMATION.FORM.DELETE')"
        variant="smooth"
        color-scheme="alert"
        icon="dismiss-circle"
        class-names="grey-btn"
        @click="removeAction"
      >
      </woot-button>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  requiredIf,
} from 'vuelidate/lib/validators';
import { BUSINESS_WEEK_DAYS, TIMES } from './constants.js';

export default {
  name: 'business-hours',
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    availableWeekDays:{
      type: Array,
      default: []
    },
  },
  validations: {
    calendar_form: {
      meetingTitle: {
        required,
      },
      meetingDescription: {
        required,
      },
      meetingLink: {
        required,
      },
      maximumMeeting: {
        required,
        minLength: minLength(1),
      },
      duration: {
        required,
        minLength: minLength(15),
      },
      maximumBufferTime: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(60),
      },
      reminder_time: {
        required,
        minLength: minLength(0),
        maxLength: maxLength(60),
      },
      timezone: {
        required,
      },
      conference_type: {
        required,
      },
    },
  },
  data() {
    return {
      weekdays: BUSINESS_WEEK_DAYS,
      times: TIMES,
      endHourTimes: TIMES,
    };
  },
  computed: {
    day: {
      get() {
        if (!this.value) return null;
        return this.value.day;
      },
      set(value) {
        const payload = this.value || {};
        this.$emit('input', { ...payload, day: value });
      },
    },
    start_hour: {
      get() {
        if (!this.value) return null;
        return this.value.start_hour;
      },
      set(value) {
        const payload = this.value || {};
        // this.$emit('input', { ...payload, start_hour: value });
        if (this.end_hour && this.end_hour <= value) {
          this.$emit('input', { ...payload, start_hour: value, end_hour: this.getNextTime(value) });
        } else {
          this.$emit('input', { ...payload, start_hour: value });
        }
      },
    },
    end_hour: {
      get() {
        if (!this.value) return null;
        return this.value.end_hour;
      },
      set(value) {
        const payload = this.value || {};
        // this.$emit('input', { ...payload, end_hour: value });
        if (this.start_hour && value <= this.start_hour) {
          this.$emit('input', { ...payload, start_hour: this.getPreviousTime(value), end_hour: value });
        } else {
          this.$emit('input', { ...payload, end_hour: value });
        }
      },
    },
    allowdWeekDaysOptions() {
      // always keep the current selected day so v-model can sow the selected item
       const currentSelectedDay = this.weekdays.find(weekday => weekday.id === this.day);
      const availableIds = this.availableWeekDays.map((day) => day.id);
      return this.weekdays.filter(
        (weekday) => availableIds.includes(weekday.id) || (currentSelectedDay && weekday.id === currentSelectedDay.id)
      );
    },
    /*
    filteredEndHourTimes() {
      // Filter endHourTimes to only include times greater than the selected start_hour
      if (!this.start_hour) return this.times;
      return this.times.filter(time => time.id > this.start_hour);
    },
    filteredStartHourTimes() {
      // Filter startHourTimes to only include times less than the selected end_hour
      if (!this.end_hour) return this.times;
      return this.times.filter(time => time.id < this.end_hour);
    },
    */
  },
  methods: {
    removeAction() {
      this.$emit('removeAction');
    },
    async onSubmit() {
      console.log('OnSubmit-149', this.$v);
    },
    onChangeStartHour(value) {
      console.log({availableWeekDays:this.availableWeekDays,weekdays:this.weekdays});
      /** On Hold
       *
       let nowPush = false;
       console.log("onChangeStartHour-135", value, Object.keys(this.endHourTimes), );
       for (const newTime of this.times) {
         if (newTime.id === value) {
           nowPush = true;
           this.endHourTimes = [];
          }
          if (nowPush) {
            this.endHourTimes.push(newTime);
          }
        }
        */
    },
    emitBusinessHours() {
      console.log(
        'LF-113',
        this.day,
        this.start_hour,
        this.end_hour,
        this.value,
        this.v
      );
    },
    getNextTime(currentTime) {
      const currentIndex = this.times.findIndex((time) => time.id === currentTime);
      return this.times[currentIndex + 1]?.id || currentTime;
    },
    getPreviousTime(currentTime) {
      const currentIndex = this.times.findIndex((time) => time.id === currentTime);
      return this.times[currentIndex - 1]?.id || currentTime;
    },

  },
};
</script>
<style>
.d-flex {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.business_hours_select {
  margin: 0 !important;
}

.grey-btn {
  /* padding: 0 5px; */
  padding: 10px !important;
}
</style>
