<template>
  <div class="column content-box">
    <div
      style="display: flex; padding: 8px; padding-left: 24px; padding-right: 24px; color:grey; align-items: center;"
    >
      <h5>{{ $t('FLOW.EDIT_AVL_CLDR_LIST') }}</h5>
    </div>
    <form class="row" @submit.prevent>
      <div class="medium-12 columns">

         <woot-input
            v-model="list.title"
            :label="$t('FLOW.CTG_LIST_TITLE')"
            :error="$v.list.title.$error ? ($v.list.title.$model.length < 1 ? 'Title must be at least 1 character.' : 'Title must be at most 60 characters.') : ''"
            :class="{ error: $v.list.title.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.TITLE')"
            @blur="$v.list.title.$touch"
          />

         <woot-input
            v-model="list.body"
            :label="$t('FLOW.CTG_LIST_BODY')"
            :error="$v.list.body.$error ? ($v.list.body.$model.length < 1 ? 'Body must be at least 1 character.' : 'Body must be at most 4096 characters.') : ''"
            :class="{ error: $v.list.body.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.BODY')"
            @blur="$v.list.body.$touch"
          />

        <woot-input
            v-model="list.footer"
            :label="$t('FLOW.CTG_LIST_FOOTER')"
            :error="$v.list.footer.$error ? ($v.list.footer.$model.length > 60 ? 'Footer must be at most 60 characters.' : '') : ''"
            :class="{ error: $v.list.footer.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.FOOTER')"
            @blur="$v.list.footer.$touch"
          />

          <woot-input
            v-model="list.section_title"
            :label="$t('FLOW.CTG_LIST_SECTION_LEVEL')"
            :error="$v.list.section_title.$error ? 'Section title must be between 1 and 24 characters.' : ''"
            :class="{ error: $v.list.section_title.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.SECTION_TITLE')"
            @blur="$v.list.section_title.$touch"
          />

          <woot-input
            v-model="list.button"
            :label="$t('FLOW.CTG_LIST_BTN_LEVEL')"
            :error="$v.list.button.$error ? ($v.list.button.$model.length < 1 ? 'Button label must be at least 1 character.' : 'Button label must be at most 20 characters.') : ''"
            :class="{ error: $v.list.button.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.BTN_LEVEL')"
            @blur="$v.list.button.$touch"
          />

          <!-- remove user id from calander list, show in appoint list  -->
          <!-- <woot-input
            v-model="list.user_id"
            :label="$t('FLOW.CTG_AVL_CLDR_LIST_USER_ID_LEVEL')"
            :error="$v.list.user_id.$error ? 'User ID is required':''"
            :class="{ error: $v.list.user_id.$error }"
            type="text"
            :placeholder="$t('FLOW.PLACEHOLDERS.BTN_LEVEL')"
            @blur="$v.list.user_id.$touch"
          /> -->

          <!-- Automatic populate the available calendar list from botpress  -->

          <!-- <section>
            <ul style="margin:0; list-style:none;">
              <li v-for="(section,sectionIdx) in list.action.sections" class="section_item" :key="sectionIdx">
                  <woot-input
                      v-model="section.title"
                      :label="$t('FLOW.CTG_LIST_SECTION_LEVEL')"
                      :error="$v.list.action.sections.$each[sectionIdx].title.$error ? 'Section title must be between 1 and 24 characters.' : ''"
                      :class="{ error: $v.list.action.sections.$each[sectionIdx].title.$error }"
                      type="text"
                      :placeholder="$t('FLOW.PLACEHOLDERS.SECTION_TITLE')"
                      @blur="$v.list.action.sections.$each[sectionIdx].title.$touch"
                  />
              </li>
            </ul>
        </section> -->


      </div>


      <div class="modal-footer">
        <woot-button @click.prevent="allDone">
          {{ $t('FLOW.DONE') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('FLOW.CANCEL') }}
        </woot-button>
      </div>

    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import FluentIcon from '../../../../../../../shared/components/FluentIcon/Index.vue';
import DashboardIcon from '../../../../../../../shared/components/FluentIcon/DashboardIcon.vue';
import FlowApi from '../../FlowApi';
import { required, minValue, maxValue,  minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';


export default {
  components: {
    FluentIcon,
    DashboardIcon,
  },

  mixins: [alertMixin],
  props: {
    dref: {
      required: true,
    },
    botid: {
      required: true,
    },
    list: {
      required: true,
    }
  },
  data() {
    return {
    };
  },
  computed:{
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),

  },
  mounted(){

  },
  watch: {

  },

  validations: {
    botid: {
      required,
    },
    list: {
      title: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(60),
      },
      body: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(4096),
      },
      footer: {
        required: false,
        maxLength: maxLength(60),
      },
      button: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(20),
      },
      section_title: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(24),
      },
      // user_id: {
      //   required,
      //   minLength: minLength(1),
      // },


    },
  },
  methods: {
    async allDone() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert("Please fill in all required fields correctly.");
        return;
      }
      this.$emit('list-edit-done', this.list);
    },

    onClose() {
      this.$emit('on-close');
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .ProseMirror-woot-style {
  height: 8rem;
}

.bottom-margin {
  margin-bottom: var(--space-small) !important;
}

.field_item {
  display: flex;
  align-items: center;
  margin-bottom: 1.6em;
}



// .item {
//   position: relative;
//   border: 1px solid var(--s-75);
//   border-radius: var(--border-radius-normal);
//   padding: 12px;
//   margin-top: 8px;
// }

.action_btn {
  position: absolute;
  top: 4px;
  right: 4px;
}
</style>
