import mixpanel from 'mixpanel-browser'


// create an instance of the Mixpanel object
const MIXPANEL_PROJECT_ID= 'baef931e1d6582743b85735b54186b6e';
mixpanel.init(MIXPANEL_PROJECT_ID, {
    persistence: 'localStorage',    // set persistence to local storage
    debug: true,                    // enable debug log in console
});

// connect mixpanel globally accessible
window.mixpanel = mixpanel;

export default mixpanel;
