import { frontendURL } from '../../../../helper/URLHelper';

const campaigns = accountId => ({
  parentNav: 'campaigns',
  routes: [
    'settings_account_campaigns',
    'one_off',
    'expressBotList',
    'settings_account_campaigns_flow',
    'settings_account_campaigns_flow_edit',
    'email_templates',
    'email_template_add',
    'email_template_edit'
  ],

  menuItems: [
    {
      icon: 'arrow-swap',
      label: 'FLOW',
      key: 'ongoingCampaigns',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/flows`),
      toStateName: 'settings_account_campaigns',
    },
    {
      key: 'oneOffCampaigns',
      icon: 'sound-source',
      label: 'ONE_OFF',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/one_off`),
      toStateName: 'one_off',
    },
    
    {
      key: 'expressBotList',
      icon: 'sound-source',
      label: 'EXPRESS_BOT',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/express-bots/list`),
      toStateName: 'expressBotList',
    },

    {
      key: 'emailTemplates',
      icon: 'sound-source',
      label: 'EMAIL_TEMPLATES',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/email_templates`),
      toStateName: 'email_templates',
    },
  ],
});

export default campaigns;
