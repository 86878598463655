<template>
  <div>
    <WootHeatMap
        :chartSeries="sales_reports"
        :onFilterSelect="onChangeMetric"
    />
  </div>
</template>

<script>
import WootHeatMap from './components/WootHeatMap.vue';
import {mapGetters} from "vuex"

export default {
  name: 'SalesReport',
  components: {
    WootHeatMap,
  },
  data() {
    return {

    };
  },
  computed:{
    ...mapGetters({
        sales_reports: "getSalesReport"
    }),
  },
  mounted(){
     this.$store.dispatch('getSalesReport',{
        metric:"sale_by_days",
        type:"order",
     });
  },
  methods:{
    onChangeMetric(selectedMetric){
        this.$store.dispatch('getSalesReport',{
            metric: selectedMetric.id,
            type:"order",
        });
    }
  },
};
</script>

<style>


</style>
