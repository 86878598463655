<template>
  <div style="position: relative">
    <div v-if="displayPopUp" class="plan-loader">
      <div class="pop-up">
        <div v-if="confirmUpdatePopup">
          <div style="margin-bottom: 15px">
            {{ this.$t('AUTOMATION.CONFIRM_SUBSCRIPTION_UPDATE') }}
          </div>
          <div style="display: flex; justify-content: center;">
            <woot-input
              v-if="promoCodeInput"
              v-model="promotionCode"
              type="text"
              placeholder="Promocode"
              style="width: 200px;"
            />
          </div>
          <p @click="openPromoCodeInput">
            {{ this.$t('AUTOMATION.APPLY_PROMO_CODE_LABEL') }}
          </p>

          <button @click="closePopUp">Cancel Payment</button> &nbsp;
          <button @click="upgradePlan(selectedPlan)">Confirm</button>
        </div>
        <div v-else>
          {{ this.$t('AUTOMATION.SUBSCRIPTION_POP_UP_HEADING') }}<br />
          {{ this.$t('AUTOMATION.SUBSCRIPTION_POP_UP_SUB_HEADING') }}<br />
          <button @click="hidePopUp">Ok</button>
        </div>
      </div>
    </div>
    <div>
      <stripe-component
        :modal-value="this.topUpModal"
        :registration-fee="registrationFee"
        :user-balance="userBalance"
        :product-id="productId"
        :old-plan-id="oldPlanId"
        @close-modal="closeModal"
      ></stripe-component>
      <div v-if="isLoadingSpinner" class="plan-spinner">
        <spinner size="large" />
      </div>

      <div v-else>
        <div v-if="currentUser.plan_id === 16" class="expire-msg">
          <div class="size-3 billing text-center"> Subscription Expired.</div>
          <p class="text-center">{{expireMessage.message}}</p>
        </div>
        <div  class="plans">
          <div v-for="plan in plans.filter(filterVisiablePlans).filter(filterSignUpTypePlans)" :key="plan.id" class="containerDiv">
            <div class="outerSection">
              <div class="section-1">
                <div class="plan size-3 text-center">{{ plan.name }}</div>
                <div class="size-1">
                  {{ currentUser.default_currency.toUpperCase() }}
                  {{ plan.yearly_pay[currentUser.default_currency].price }}
                </div>
                <div v-if="!ifAppsumoUser" class="m-2 size-3 billing text-center">BILLED YEARLY</div>
                <div class="text-center" style=" margin-top: 5%">
                  <div class="size-3 products">{{ plan.product }}</div>
                </div>
                <ul>
                  <li v-for="(feature, index) in plan.features" :key="index">
                    <div class="row" style="flex-flow: nowrap">
                      <div>
                        <fluent-icon
                          icon="checkmark-circle"
                          color="green"
                          size="15"
                          style="margin: 2px"
                        />
                      </div>
                      <p>{{ feature }}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <!-- Check if the user is an appsumo user or not -->
                <div v-if="checkIsAppsumoUser" class="section-2">
                  <button v-if="getApsumoPlanButton(plan) === 'switch'" @click="handleAppsumoPlan(plan)">Switch</button>
                  <button v-else-if="getApsumoPlanButton(plan) === 'unsubscribe'" @click="handleAppsumoPlan(plan)">Unsubscribe</button>
                </div>

                <!-- buttons for normal users  -->
                <div v-else>
                  <div  class="section-2">
                    <button
                      v-if="plan.id === userPlan.id"
                      style="background-color: #38bf32"
                    >
                      Active
                    </button>
                    <button
                      v-else-if="
                        userPlan.yearly_pay[currentUser.default_currency] &&
                        (plan.yearly_pay[currentUser.default_currency].price <
                          userPlan.yearly_pay[currentUser.default_currency].price)
                      "
                      style="background-color: rgba(37,36,36,0.55)"
                    >
                      Disabled
                    </button>
                    <button v-else @click="openCheckout(plan, userPlan)">Upgrade</button>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- block the full page not to click by user  -->
      <div v-if="isStripeSessionLoading" class="full_loader">
        <div class="size-1">Please wait...</div>
        <Spinner size="medium" />
      </div>
    </div>
  </div>
</template>

<script>
import Stripe from '../../../../api/stripe';
import alertMixin from '../../../../../shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import StripeComponent from '../../../../components/stripe/StripeComponent';
import StripeAPI from '../../../../api/stripe';
import Spinner from '../../../../../shared/components/Spinner.vue';
import endPoints from "../../../../api/endPoints";
import {DEFAULT_REDIRECT_URL} from "../../../../constants"
import {SIGNUP_FLOW} from "../../../../helper/demoAutomationHelper"
import {getParamsFromUrl, frontendURL} from "../../../../helper/URLHelper"

export default {
  components: {
    StripeComponent,
    Spinner,
  },
  mixins: [alertMixin],
  data() {
    return {
      userInboxesDetails: {},
      topUpModal: false,
      registrationFee: false,
      productId: '',
      userBalance: 0,
      oldPlanId: '',
      displayPopUp: false,
      confirmUpdatePopup: false,
      promoCodeInput: false,
      isLoadingSpinner: false,
      promotionCode: '',
      selectedPlan: '',
      isStripeSessionLoading:false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      ifAppsumoUser: 'ifAppsumoUser',
      plans: 'accounts/getSubscriptionPlans',
      userPlan: 'accounts/getUserPlan',
      getCurrentAccount: 'accounts/getAccount',
    }),
    currentAccount(){
      return this.getCurrentAccount(this.$route.params.accountId)
    },
    combinedData() {
      return `${this.plans}|${this.userPlan}`;
    },
    expireMessage(){
      if (this.currentUser.trial) {
        return {
          message:"Please select a plan for free 30 days trial, no charges will be made to your credit card",
        }
      }else{
        return {
          message:"To maintain continued access, kindly renew your subscription. In the event of a lapse exceeding 60 days, your account will be terminated."
        }
      }
    },
    checkIsAppsumoUser(){
      return this.currentUser.appsumo;
    },

  },
  watch: {
    combinedData() {
      // console.log('watch1', this.plans);
      // console.log('watch2', this.userPlan);
      // console.log("User Object =",this.currentUser);
      if (this.userPlan) {
        if (Array.isArray(this.plans)) {
          if (this.plans.length > 0) {
            // buy_now_plan_id could be in query params or with accountObj
            let buyNowPlanId = this.$route.query.buy_now_plan_id ? this.$route.query.buy_now_plan_id : '';
            const isStripeOpenOnce = localStorage.getItem("stripeOpenByNowPlan");

            if (!buyNowPlanId && ! isStripeOpenOnce) {
              // backend will pass it in account object
              buyNowPlanId = this.currentAccount.custom_attributes?.buy_now_plan_id ? this.currentAccount.custom_attributes?.buy_now_plan_id: "";
              // set localstorage to keep track we are only allow once to open stripe
              // clear this storage when logout
              localStorage.setItem('stripeOpenByNowPlan',true);
            }
            buyNowPlanId = String(buyNowPlanId);
            // console.log('buyNowPlanId', buyNowPlanId);
            if (buyNowPlanId !== '') {
              if (String(this.userPlan.id) !== String(buyNowPlanId)) {
                this.plans.some(plan => {
                  if (String(plan.id) === String(buyNowPlanId)) {
                    // console.log('Checking out', plan);
                    this.openCheckout(plan, this.userPlan);
                    return true;
                  }
                  return false;
                });
              }
            }
          }
        }
      }
    },
    // for stripe check out and plan_id change
    'currentUser.plan_id': function(newPlanId, oldPlanId){
      this.checkoutSuccessfulRedirect(newPlanId, oldPlanId);
    },
  },
  async mounted() {
    this.checkoutSuccessfulRedirect();
    await StripeAPI.InitializeStripeCredentials();
  },

  methods: {
    closePopUp() {
      this.promoCodeInput = false;
      this.promotionCode = '';
      this.displayPopUp = false;
    },
    hidePopUp() {
      this.displayPopUp = false;
      this.isLoadingSpinner = true;
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    },
    openPromoCodeInput() {
      this.promoCodeInput = true;
    },
    closeModal() {
      this.topUpModal = false;
    },
    openTopUpModal() {
      this.topUpModal = !this.topUpModal;
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async openCheckout(plan, oldPlan) {
      // console.log('updated to plan', plan.id, oldPlan?.id);
      this.selectedPlan = plan;
      const oldPlanId = oldPlan?.id ? oldPlan?.id : '';
      window.dataLayer.push({
        event: 'ps_upgrade_attempt',
        ps_old_plan: oldPlan.id || '',
        ps_new_plan: plan.id || '',
      });
      // console.log('dataLayer Updated', window.dataLayer);
      this.oldPlanId = oldPlanId.toString();

      if (plan.name === 'Prepaid') {
        this.userInboxesDetails = await this.$store.dispatch(
          'accounts/fetchAccountInboxes'
        );
        // this.productId =
        //   this.userInboxesDetails.name === null ? plan.product_id : '';
        // this.registrationFee = this.userInboxesDetails.name === null;
        let top_up = await this.$store.dispatch('accounts/fetchTopUpDetails');
        this.userBalance = top_up.amount;
        this.productId = plan.product_id;
        this.registrationFee = true;

        return this.openTopUpModal();
      }
      if (this.userPlan.name !== 'Free' && this.userPlan.name !== 'Prepaid') {
        await this.updateSubscription(plan);
        return true;
      }
      await this.createSubscription(plan);
      return true;
    },

    async createSubscription(plan) {
      // add a full page loader to ctop click another button
      this.isStripeSessionLoading = true;

      if (!plan.yearly_pay[this.currentUser.default_currency]) {
        this.showAlert("This plan does not allow user's default currency");
      }
      // demo response to test
      const sessionData = await Stripe.createStripeSession(
        plan.yearly_pay[this.currentUser.default_currency].id,
        this.currentUser.account_id
      );
      if (sessionData.status === 500) {
        return this.showAlert(sessionData.message);
      }
      // set at $peasy here
      // setLabel and triggerEvent at $peasy for checkout plan
      window.$peasy.setUser(this.currentUser.email,{
        name: this.currentUser.name,
        email: this.currentUser.email,
      })
      setTimeout(function(){
        window.$peasy?.setLabel("upgrade_attempt");
        window.$peasy.triggerEvent({event_name:"upgrade_attempt",event_params:{package_id:plan.id}})
      },2000)

      await this.delay(3000)


      // end the page loader
      this.isStripeSessionLoading = false;

      // redirecting to stripe checkout page _._
      return window.stripe.redirectToCheckout({
        sessionId: sessionData.session.id,
      });
    },

    async updateSubscription(plan) {
      const payment_methods = await Stripe.getSavedPaymentMethods();
      if (
        payment_methods.status === 200 &&
        payment_methods.session.data.length > 0
      ) {
        this.confirmUpdatePopup = true;
        this.displayPopUp = true;
        return true;
      }
      await this.createSubscription(plan);
      return true;
    },

    async upgradePlan(plan) {
      const subscriptionUpdateData = await Stripe.updateSubscriptionPlan(
        plan.yearly_pay[this.currentUser.default_currency].id,
        this.currentUser.account_id,
        this.promotionCode
      );
      if (subscriptionUpdateData.status === 500) {
        return this.showAlert('Payment failed. Please check the Coupon code.');
      }
      if (subscriptionUpdateData.status === 200) {
        this.confirmUpdatePopup = false;
      }
      return true;
    },

    filterVisiablePlans(pl){
      return pl.visible !== false;
    },
    filterSignUpTypePlans(pl){
      if (this.ifAppsumoUser) {
        return pl.appsumo ? true : false;
      }else{
        return pl.appsumo ? false : true;
      }
    },

    getApsumoPlanButton(plan){
      const endDate = new Date(this.currentUser.end_date);
      const toDay = new Date();
      const isActive = endDate > toDay ;

      if (isActive && this.currentUser.plan_id === plan.id) {
        return 'unsubscribe';
      }else{
        return 'switch';
      }
    },
    handleAppsumoPlan(plan){
      const invoice_item_uuid = this.currentUser.invoice_item_uuid;
      if (!invoice_item_uuid) {
        return
      }
      const appsumoUrl = endPoints('appsumoPlanChange').url(invoice_item_uuid);
      const anchorElement = document.createElement("a");
      anchorElement.href = appsumoUrl;
      anchorElement.target = '_blank';
      anchorElement.click();
    },

    checkoutSuccessfulRedirect(newPlanId,oldPlanId){
      const isCheckoutSuccess = this.$route.query.upgradeSuccessful === 'true';
      let plan_id = newPlanId || this.currentUser.plan_id;
      const isPaidUser = plan_id && (plan_id !== 16);
      if (isCheckoutSuccess && isPaidUser) {
        // if first time signup, user.category=retail|fnb, navigate to catalog create
        try {
          const signupFlow = SIGNUP_FLOW.getFlow();
          const isValidSector = ['retail','fnb'].includes(this.currentUser.sector);

          if(signupFlow?.isNewSignup && signupFlow?.current_step === SIGNUP_FLOW.FLOWS.select_plan && isValidSector){
            const nextsignupflow = {
              ...signupFlow,
              current_step: SIGNUP_FLOW.FLOWS.catalog_item
            }
            SIGNUP_FLOW.setFlow(nextsignupflow)
            const query = getParamsFromUrl()
            // if user.category = 'retail' or 'fnb'
            // this.$router.push({
            //   path: `/app/accounts/${this.currentUser.account_id}/catalog/list`,
            //   query: {...query}
            // });
            window.location = `/app/accounts/${this.currentUser.account_id}/catalog/list`
            return
          }
          // goTo => addCatalogItem -> catalogSetting(QR code)
          // Redirect to the dashboard
          this.$router.push(`${DEFAULT_REDIRECT_URL}accounts/${this.currentUser?.account_id}/target-dashboard/goal`);

        } catch (errorR) {
          console.log(errorR);
        }

      }
    },

  },
};
</script>
<style scoped>
.plan-spinner {
  text-align: center;
  padding: 15vw;
  height: 100vh;
}
.spinner:before {
  height: 3rem;
  width: 3rem;
}
.pop-up {
  font-size: 1.5rem;
  padding: 2rem;
  border: 1px solid white;
  border-radius: 10px;
  background: white;
}
.pop-up p {
  text-align: center;
  color: #6f1cdf;
  cursor: pointer;
}
.plan-loader {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 8rem 15rem;
  background: #9f9f9f9f;
}
.plan-loader button {
  margin-top: 35px;
  padding: 15px 25px;
  background: #6f1cdf;
  font-size: 13px;
  font-weight: bold;
  border-radius: 25px;
  color: white;
}
.plans {
  display: flex;
  flex-wrap: wrap;
  flex-flow: wrap;
}
.outerSection {
  margin: 10px;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  display: flex;
  border: 1px solid #c4c4c4cf;
  border-radius: 7px;
  box-shadow: #00000069 0px 0px 10px;
}
.containerDiv {
  width: 100%;
  margin: 10px 0px;
}
p {
  text-align: start;
  font-size: 1.3rem;
  margin-bottom: 0px;
}
.plan {
  margin-top: 10%;
  height: 45px;
}
.billing {
  height: 35px;
}
.products {
  height: 45px;
}
.m-2 {
  margin-top: 5%;
}
.expire-msg{
  margin-top: 20px;
  text-align: center;
}
.expire-msg p{
  text-align: center !important;
}
.size-1 {
  font-size: 35px;
  font-weight: bolder;
  margin-top: 5%;
  white-space: nowrap;
  text-align: center;
}
.size-3 {
  font-size: 15px;
  font-weight: bold;
}
ul {
  list-style-type: none;
  margin: 20px 0px;
}
.section-1,
.section-2 {
  margin: 10px 10% 5px;
}
button {
  cursor: pointer;
}
.section-2 button {
  font-size: 13px;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 10px;
  background: #6f1cdf;
  border-radius: 25px;
  width: 100%;
  color: white;
}
@media (min-width: 480px) {
  .containerDiv {
    width: 50%;
  }
}
@media (min-width: 720px) {
  .containerDiv {
    width: 33%;
  }
  .pop-up {
    font-size: 1.7rem;
  }
  .plan-loader {
    padding: 15rem 30rem;
  }
}
@media (min-width: 1020px) {
  .containerDiv {
    width: 25%;
  }
  .pop-up {
    font-size: 2rem;
    padding: 5rem;
  }
}
@media (min-width: 1800px) {
  .containerDiv {
    width: 300px;
  }
}

.full_loader{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(1px);
}
</style>
