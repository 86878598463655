<template>
    <div v-if="isOpen">
        <!-- form data & current form Node -->
        <modal
            :show.sync="isOpen"
            :on-close="closeModal"
            modalMainContainerClass="botExpressModal"
            closeBtnClass="modal_close_btn_left"
            closeIcon="chevron-left"
        >
            <div class="botExpressModal_container">
                <div class="header">
                    <h2 class="header_title">{{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.TITLE")}}</h2>
                </div>

                <div class="step_range">
                    <input
                        id="expressBot_step_range" type="range" name="volume" :value="currentStepIndex"
                        :min="0"
                        :max="totalSteps"
                        disabled
                    />
                </div>

                <div v-if="!isFinalStepQrModalOpen">
                    <section class="expressBot">
                        <aside>
                            <!-- <h2 class="main_title">{{labels[current_step].formTitle}}</h2>
                            <p class="main_description">{{labels[current_step].formDescription}}</p> -->
                            <h2 class="main_title">{{$t(labels[current_step].formTitle)}}</h2>
                            <p class="main_description">{{$t(labels[current_step].formDescription)}}</p>

                            <div v-for="(item,index) in currentForm.contents" :key="index">

                                <!-- if type card  -->
                                <template v-if="item.type === 'card'">
                                    <label class="form_label">
                                        {{$t(labels[current_step].contents[index].title)}}
                                        <input v-model="item.title" type="text" />
                                    </label>
                                    <label class="form_label">
                                        {{$t(labels[current_step].contents[index].subtitle)}}
                                        <!-- if item.extraConfig.isMentionOperatingHour -->
                                        <textarea v-model="item.subtitle" name="" id="" cols="20" rows="6"/>
                                    </label>

                                    <!-- if mention operating hour -->
                                    <label v-if="item.extraConfig && 'isMentionOperatingHour' in item.extraConfig" class="form_label check_label">
                                        <input v-model="item.extraConfig.isMentionOperatingHour" type="checkbox" name="" id="">
                                        {{$t(labels[current_step].extraConfig.isMentionOperatingHour)}}
                                    </label>

                                    <template v-for="(actionBtn,btnIndex) in labels[current_step].contents[index].actionButtons">
                                        <div v-if="actionBtn" :key="'actionBtn'+btnIndex">
                                            <label v-if="actionBtn.button" class="form_label">
                                                {{$t(actionBtn.button)}}
                                                <input v-model="item.actionButtons[btnIndex].title" type="text" name="" id="">
                                            </label>
                                            <label v-if="actionBtn.checkbox" class="form_label check_label">
                                                <input v-model="item.actionButtons[btnIndex].visibility" type="checkbox" name="" id="">
                                                {{$t(actionBtn.checkbox)}}
                                            </label>
                                        </div>
                                    </template>
                                </template>

                                <!-- if type category_list  -->
                                <template v-if="item.type === 'category_list'">
                                    <label class="form_label">
                                        {{$t(labels[current_step].contents[index].title)}}
                                        <input v-model="item.title" type="text" />
                                    </label>
                                    <label class="form_label">
                                        {{$t(labels[current_step].contents[index].body)}}
                                        <textarea v-model="item.body" name="" id="" cols="20" rows="6"/>
                                    </label>
                                    <label class="form_label">
                                        {{$t(labels[current_step].contents[index].footer)}}
                                        <input v-model="item.footer" type="text" />
                                    </label>
                                    <label class="form_label">
                                        {{$t(labels[current_step].contents[index].button)}}
                                        <input v-model="item.button" type="text" />
                                    </label>


                                    <!-- How can we take section data ?  -->
                                    <template v-for="(section,sectionIdx) in item.sections">
                                        <div :key="'actionBtn'+sectionIdx">
                                            <label class="form_label">
                                                {{$t(labels[current_step].contents[index].sections[sectionIdx].title)}}
                                                <input v-model="section.title" type="text" />
                                            </label>
                                            <multiselect
                                                class="column small-12 express_bot_multiselect"
                                                v-model="section.rows"
                                                :options="categoryOptions"
                                                track-by="id"
                                                label="title"
                                                :multiple="true"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :hide-selected="true"
                                                :placeholder="$t('CATALOGUE.TOOLBAR.CATAGORY_PLACEHOLDER')"
                                                selected-label
                                                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                                                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                                            />
                                        </div>
                                    </template>


                                </template>


                                <!-- if type text  -->
                                <template v-if="item.type === 'text'">
                                    <label class="form_label">
                                        {{$t(labels[current_step].contents[index].value)}}
                                        <textarea
                                            v-if="item.inputType === 'textarea'"
                                            v-model="item.value"
                                            name="" id="" cols="20" rows="6"
                                        />
                                        <input v-else v-model="item.value" type="text" />
                                    </label>
                                    <!-- if includeGoogleMap show checkbox  -->
                                    <!-- if any foreign field need to update  -->
                                    <div  v-if="hasExtraConfig(item,'foreignContents')">
                                        <template v-for="(extraItem,exIdx) in item.extraConfig.foreignContents" >
                                            <label v-if="extraItem.contentType === 'card'" class="form_label" :key="'ex'+exIdx">
                                                {{$t(labels[current_step].contents[index].foreignContents[exIdx].title)}}
                                                <input
                                                    v-model="form_nodes[extraItem.step].contents[extraItem.contentIdx].actionButtons[extraItem.btnIndex].title"
                                                    type="text" name="" id=""
                                                >
                                            </label>
                                        </template>
                                    </div>
                                </template>


                                <!-- if type image  -->
                                <template v-if="item.type === 'image'">
                                    <label class="form_label">
                                        {{$t(labels[current_step].contents[index].title)}}
                                        <textarea
                                            v-if="item.inputType === 'textarea'"
                                            v-model="item.title"
                                            name="" id="" cols="20" rows="6"
                                        />
                                        <input v-else v-model="item.title" type="text" />
                                    </label>
                                </template>

                            </div>

                        </aside>
                        <aside>
                            <ExpressPreview
                            :currentForm="formattedCurrentForm"
                            />
                        </aside>
                    </section>


                    <footer class="row submit_btns">
                        <aside class="medium-6 columns">
                            <div v-if="isLastLabel">
                                <label class="form_label">
                                    <!-- Which inbox would you like to create this chatbot on? -->
                                    {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.LABELS.BOT_INBOX")}}
                                </label>
                                <multiselect
                                    class="column small-12 express_bot_multiselect"
                                     v-model="selectedInbox"
                                    :options="whatsAppInboxList"
                                    track-by="id"
                                    label="name"
                                    :multiple="false"
                                    :close-on-select="false"
                                    :clear-on-select="false"
                                    :hide-selected="true"
                                    :placeholder="$t('MESSAGE_MGMT.ADD.FORM.INBOX_PLACEHOLDER')"
                                    selected-label
                                    :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                                    :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                                    @select="onSelectInbox($event)"
                                />
                            </div>

                            <woot-button
                                v-if="!isLastLabel"
                                class-names="tmp_submit"
                                :is-loading="isSubmitting"
                                @click="onNextHandler(false)"
                            >
                                <!-- Next -->
                                {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.NEXT")}}
                            </woot-button>
                            <woot-button
                                v-if="isLastLabel"
                                class-names="tmp_submit"
                                :is-loading="isSubmitting"
                                @click="onNextHandler(true)"
                            >
                                <!-- Submit -->
                                {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.SUBMIT")}}
                            </woot-button>
                            <woot-button v-if="isPreviousAvailable" class-names="tmp_submit" variant="clear" @click="onPreviousHandler">
                                <!-- Previous -->
                                {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.PREVIOUS")}}
                            </woot-button>
                            <woot-button v-else class-names="tmp_submit" variant="clear" @click="closeModal">
                            {{$t("MESSAGE_MGMT.ADD.BTNS.CANCEL")}}
                            </woot-button>
                        </aside>
                    </footer>
                </div>

                <div v-if="isFinalStepQrModalOpen">

                    <div>
                        <QRCode :shop_url="whatsappUrl" :isOnlyQR="true" />
                    </div>
                    <div class="complete_step">
                        <div class=" header info">
                            <h2 class="header_title">{{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.TEST_TITLE")}}</h2>
                            <p class="description">{{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.TEST_DESCRIPTION")}}</p>
                        </div>
                        <div class="btns">
                            <woot-button
                                class-names="tmp_submit"
                                @click="onTryChatbotClick"
                            >
                                {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.TRY")}}
                            </woot-button>
                            <woot-button class-names="tmp_submit" variant="clear" @click="closeModal">
                                {{$t("FLOW.EXPRESS_BOT.CREATE_MODAL.BTNS.CLOSE")}}
                            </woot-button>

                        </div>
                    </div>


                </div>

            </div>
        </modal>
    </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import Input from '../../../../components/widgets/forms/Input.vue';
import ExpressPreview from "./ExpressPreview.vue";
import { mapGetters } from 'vuex';
import { formAndAccountMerger} from "./expressBot";
import QRCode from "../../catalogue/settings/QRCode.vue";
import alertMixin from 'shared/mixins/alertMixin';




export default {
    components:{
        Modal,
        ExpressPreview,
        Input,
        QRCode,
    },
    mixins: [alertMixin],
    props:{
        isOpen: {
            type: Boolean,
            default: false
        },
        isFinalStepQrModalOpen: {
            type: Boolean,
            default: false
        },
        isSubmitting: {
            type: Boolean,
            default: false
        },
        current_step: {
            type: String,
            default: ''
        },
        labels: {
            type: Object,
            default: {}
        },
        form_nodes: {
            type: Object,
            default: {}
        },
        botform: {
            type: Object,
            default: {}
        },
        onNext: {
            type: Function,
            default: (next_step)=>{}
        },
        onPrevious: {
            type: Function,
            default: (pre_step)=>{}
        },
        // formAndAccountMerger: {
        //     type: Function,
        //     default: ()=>{}
        // },
    },
    data(){
        return {
            // isOpen: false,
        }
    },
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
            merchant: 'merchant/getMerchant',
            uiFlags: "catalog/getUIFlags",
            categories: "catalog/getCatagories",
            inboxes: 'inboxes/getInboxes',
        }),
        currentForm(){
            return this.form_nodes[this.current_step]
        },
        isLastLabel(){
            const steps = Object.keys(this.labels);
            const currentIndex = steps.indexOf(this.current_step);
            return currentIndex === steps.length - 1;
        },
        isPreviousAvailable() {
            const steps = Object.keys(this.labels);
            const currentIndex = steps.indexOf(this.current_step);
            return currentIndex > 0; // True if not the first step
        },
        currentStepIndex() {
            const steps = Object.keys(this.labels);
            return steps.indexOf(this.current_step);
        },
        totalSteps() {
            return Object.keys(this.labels).length - 1; // Maximum index value
        },
        formattedCurrentForm(){
            const formresult = formAndAccountMerger(this.merchant,this.botform,this.currentUser).form_data.form_nodes[this.current_step];
            return formresult
        },
        categoryOptions(){
            if (this.categories) {
                return this.categories.map(({id,title})=>({id,title}))
            }
            return []
        },
        whatsappUrl(){
            return `https://wa.me/${ this.selectedInbox?.phone_number}?text=PZ:${this.currentUser.account_id}`;
        },
        whatsAppInboxList(){
            const inboxes = this.inboxes.filter(el=>el.channel_type === "Channel::Whatsapp");
            // find the selected inbox and update
            return inboxes;
        },
        selectedInbox: {
            get() {
                // Find the object by id in the options list
                return this.whatsAppInboxList.find(
                (item) => item.id === this.botform.form_data.inbox
                );
            },
            set(selected) {
                // Set only the id value
                this.botform.form_data.inbox = selected ? selected.id : null;
            },
        },

    },
    mounted(){
        this.$store.dispatch("catalog/getCatagories");
    },
    methods:{
        closeModal(){
            this.$emit('onClose');
        },
       onPreviousHandler() {
            const steps = Object.keys(this.labels);
            let currentIndex = steps.indexOf(this.current_step);
            if (currentIndex === -1) {
                return null;
            }

            while (currentIndex > 0) {
                const previousIndex = currentIndex - 1;
                const previousStep = steps[previousIndex];
                const dependency = this.form_nodes[previousStep]?.dependency;

                if (dependency) {
                    const isDependencyMet = this.isPassDependency(dependency.actionButtons);
                    if (isDependencyMet) {
                        this.onPrevious(previousStep);
                        return;
                    }
                } else {
                    this.onPrevious(previousStep);
                    return;
                }
                currentIndex--;
            }
            const firstStep = steps[0];
            this.onPrevious(firstStep);
        },
        async onNextHandler(isSubmit=false){
            // update all "account_id", "google location",
            // do a loop on current step and update any dynamic fields for account_id

            const steps = Object.keys(this.labels);
            let currentIndex = steps.indexOf(this.current_step);

            if (currentIndex === -1) {
                return null; // Invalid current step
            }

            // this.onNext('Greeting', true);
            // return

            if (isSubmit) {
                this.onNext(this.current_step, true);
                return;
            }

            // Iterate to find the next valid step
            while (currentIndex < steps.length - 1) {
                const nextIndex = currentIndex + 1;
                const nextStep = steps[nextIndex];
                const dependency = this.form_nodes[nextStep]?.dependency;

                if (dependency) {
                    const isDependencyMet = this.isPassDependency(dependency.actionButtons);
                    if (isDependencyMet) {
                        this.onNext(nextStep,isSubmit);
                        return;
                    }
                } else {
                    // No dependency, go to the next step
                    this.onNext(nextStep,isSubmit);
                    return;
                }

                // Skip this step if dependency is not met
                currentIndex++;
            }




            // const next_step = steps[currentIndex + 1];
            // this.onNext(next_step);
        },
        isPassDependency({node,btn_id}){
            const formNode = this.form_nodes[node];
            if (formNode.contents) {
                for(const content of formNode.contents){
                    if (content.type === 'card' && content.actionButtons) {
                        for(const btn of content.actionButtons){
                            if (btn.id === btn_id) {
                                if (btn.visibility) {
                                    return true
                                }else{
                                    return false
                                }
                            }
                        }
                    }
                }
            }
            return true
        },

        hasExtraConfig(item,key){
            if (item.extraConfig && item.extraConfig[key]) {
                return item.extraConfig[key]
            }
            return false;
        },
        updateButtonByIndex(node,contentIndex,btnIndex,value){
            // this.form_nodes[node].contents[contentIndex].actionButtons[btnIndex].title = value;
        },
        onTryChatbotClick(){
            this.copyWhatsappLink();
        },
        async copyWhatsappLink() {
            try {
                // Copy to clipboard
                await navigator.clipboard.writeText(this.whatsappUrl);
                this.showAlert(this.$t('COMMUNAL_WP_SETTING.COPY_WP_SHARE_LINK_SUCCESS'));
            } catch (error) {
                console.error('Failed to copy WhatsApp link:', error);
            }
        },
        onSelectInbox(selectedItem){
            // console.log({selectedItem});
        },

        // end methods
    },
}
</script>

<style lang="scss" scoped>
.botExpressModal_container{
    .header{
        margin-left: 28px;
        .header_title{
            font-weight: 700;
            font-size: 20px;
            color: #1F1F1F;
        }
    }
}

.expressBot{
    .main_title{
        font-weight: 700;
        font-size: 14px;
        color: #1F1F1F;
    }
    .main_description{
        font-weight: 500;
        font-size: 14px;
        color: #858585;
    }
    .form_label{
        font-weight: 500;
        font-size: 12px;
        color: #1F1F1F;
        margin-top: 12px;

        input{
            font-size: 12px;
        }
        textarea{
            font-size: 12px;
        }

        .textField{
            margin-top: 8px;
            padding: 12px;
            font-weight: 500;
            font-size: 14px;
            color: #1F1F1F;
            border-color: #EEEEEE;
        }
        .textField::placeholder{
            color: #B8B8B8;
        }
    }
    .check_label{
        display: flex;
        align-items: center;
        gap: 4px;
        input{
            margin: 0;
        }
    }
}

.complete_step{
    max-width: 340px;
    margin: auto;
    text-align: center;
    .info{
        margin: 30px auto 24px auto;
    }

    .description{
        font-weight: 500;
        font-size: 14px;
        color: #858585;
    }
    .btns{
        button{
            margin-top: 16px;
        }
    }

}

.step_range {
  margin-top: 24px;
  input[type="range"] {
    width: 100%;
  }
}

.expressBot{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    margin-top: 24px;
}

.submit_btns{
    margin-top: 24px;
    .tmp_submit{
        margin-top: 16px;
    }
}
</style>


<style>
/* global css  */
.botExpressModal{
    padding: 40px 32px;
    max-height: fit-content;
    width: 724px;
}

.botExpressModal .step_range input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
}

.botExpressModal .step_range input[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  background: #F2E7FE;
  border-radius: 16px;
}

.botExpressModal .step_range input[type="range"]::-moz-range-track {
  height: 15px;
  background: #F2E7FE;
  border-radius: 16px;
}

.botExpressModal .step_range input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: #8D25F6;
  border-radius: 50%;
  border: 2px solid #8D25F6;
  box-shadow: -407px 0 0 400px #8D25F6;
}

.botExpressModal .step_range input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #8D25F6;
  border-radius: 50%;
  border: 1px solid #8D25F6;
  box-shadow: -407px 0 0 400px #8D25F6;
}
.botExpressModal .tmp_submit{
    display: block;
    width: 100%;
}

.express_bot_multiselect .multiselect__tag span,
.express_bot_multiselect .multiselect__input,
.express_bot_multiselect .multiselect__option span {
    font-size: 12px;
}
</style>