<template>
  <div :id="chartId" class="chart-container">
    <div ref="chartContainer"></div>
  </div>
</template>

<script>
export default {
  name: 'ReusableHeatmapChart',
  props: {
    chartId: {
      type: String,
      default: 'heatmap_chart',
    },
    series: {
      type: Array,
      required: true,
    },

  },
  data() {
    return {
      chart: null, // Store the chart instance
    };
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    series: {
      deep: true,
      handler(newSeries) {
        if (this.chart) {
          this.chart.updateSeries(newSeries);
              this.chart.updateOptions(this.generateChartOption(), false, false);
        }
      },
    },
    // options: {
    //   deep: true,
    //   handler(newOptions) {
    //     if (this.chart) {
    //       console.log('Updating chart options:', newOptions);
    //       this.chart.updateOptions(newOptions, false, false);
    //     }
    //   },
    // },
  },
  beforeDestroy() {
    // Destroy the chart instance to prevent memory leaks
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    renderChart() {
      if (window.ApexCharts) {
        // Merge default options with props
        const chartOptions = {
          ...this.generateChartOption(),
          series: this.series,
        };

        // Create and store the chart instance
        this.chart = new ApexCharts(this.$refs.chartContainer, chartOptions);

        // Render the chart
        this.chart.render();
      } else {
        console.error('ApexCharts is not loaded');
      }
    },
    generateChartOption(){
        const allValues = this.series.flatMap(item =>
            item.data.map(dataPoint => dataPoint.y)
        );
        const minValue = allValues.length > 0 ? Math.min(...allValues) : 0;
        const maxValue = allValues.length > 0 ? Math.max(...allValues) : 1;
        
        const defaultOptions = {
          chart: {
            type: 'heatmap',
            height: '100%',
            width: '100%',
            toolbar: {
                show: false,
                tools: {
                    export: false, // Disable the download menu button
                },
            },
          },
          plotOptions: {
            heatmap: {
                shadeIntensity: 0.5,
                radius: 0,
                useFillColorAsStroke: true,
                stroke: {
                    width: 1,
                    colors: ['rgba(111, 29, 223, 0.2)'],

                },
                colorScale: {
                    // ranges:  [
                    //     {
                    //     from: -1,
                    //     to: 0, // Exact 0 value
                    //     color: 'rgba(111, 29, 223, 0.2)', // Light purple for 0
                    //     },
                    //     {
                    //     from: minValue,
                    //     to: minValue + (maxValue - minValue) * 0.25, // First 25% of values
                    //     color: 'rgba(111, 29, 223, 0.4)',
                    //     },
                    //     {
                    //     from: minValue + (maxValue - minValue) * 0.25 + 1,
                    //     to: minValue + (maxValue - minValue) * 0.5, // Next 25% of values
                    //     color: 'rgba(111, 29, 223, 0.6)',
                    //     },
                    //     {
                    //     from: minValue + (maxValue - minValue) * 0.5 + 1,
                    //     to: minValue + (maxValue - minValue) * 0.75, // Next 25% of values
                    //     color: 'rgba(111, 29, 223, 0.8)',
                    //     },
                    //     {
                    //     from: minValue + (maxValue - minValue) * 0.75 + 1,
                    //     to: maxValue,
                    //     color: 'rgba(111, 29, 223, 1)',
                    //     },
                    // ],
                },
            },
          },
           yaxis: {
            labels: {
              style: {
                fontSize: '14px',
              },
            },
            min: minValue || 0,
          },
          colors: ['#00FF00', '#6F1DDF'],
        }
        console.log({defaultOptions});
        return defaultOptions;
    },
    // end method
  },

};
</script>

<style scoped>
.chart-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  height: 500px;
}
</style>
