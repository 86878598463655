<template>
  <form class="row" @submit.prevent="createChannel()">
    <div class="medium-8 columns">
      <label :class="{ error: $v.inboxName.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL') }}
        <input
          v-model.trim="inboxName"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
          @blur="$v.inboxName.$touch"
        />
        <span v-if="$v.inboxName.$error" class="message">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR') }}
        </span>
      </label>
    </div>

    <div class="medium-8 columns">
      <label :class="{ error: $v.phoneNumber.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL') }}
        <input
          v-model.trim="phoneNumber"
          type="text"
          :disabled="!whatsappInbox"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER')"
          @blur="$v.phoneNumber.$touch"
        />
        <span v-if="$v.phoneNumber.$error" class="message">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR') }}
        </span>
      </label>
    </div>

    <div v-if="whatsappInbox" class="medium-8 columns">
      <label :class="{ error: $v.apiKey.$error }">
        <span>
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.API_KEY.LABEL') }}
        </span>
        <input
          v-model.trim="apiKey"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.API_KEY.PLACEHOLDER')"
          @blur="$v.apiKey.$touch"
        />
        <span v-if="$v.apiKey.$error" class="message">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.API_KEY.ERROR') }}
        </span>
      </label>
    </div>

    <div class="medium-12 columns">
      <woot-submit-button
        :loading="uiFlags.isCreating"
        :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
        :disabled="is360HubModalOpen"
      />
      <!-- new register to invok 360 API -->

      <woot-submit-button
        v-if="selectedProvider === '360dialog'"
        :loading="is360HubModalLoading"
        type="button"
        :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON_360')"
        @click="open360DialogPopup()"
      />

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import Hub360Channel from "dashboard/api/channel/Hub360Channel.js";

const shouldStartWithPlusSign = (value = '') => value.startsWith('+');

export default {
  mixins: [alertMixin],
  props: {
    whatsappInbox: {
      type: Boolean,
      default: true,
    },
    communalInboxesDetails: {
      type: Object,
      default: () => {},
    },
    selectedProvider:{
      type: String
    }
  },
  data() {
    return {
      inboxName: '',
      phoneNumber: this.fetchPhoneNumber(),
      apiKey: this.fetchApiKey(),
      is360HubModalOpen: false,
      is360HubModalLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      globalConfig: 'globalConfig/get',
    }),
  },
  validations: {
    inboxName: { required },
    phoneNumber: { required, shouldStartWithPlusSign },
    apiKey: { required },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.inboxName,
            channel: {
              type: 'whatsapp',
              phone_number: this.phoneNumber,
              communal_type: this.whatsappInbox === false,
              provider: this.getCommunalProvider(),
              provider_config: {
                api_key: this.apiKey
              },
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE'));
      }
    },
    fetchPhoneNumber() {
      return this.whatsappInbox
        ? ''
        : this.communalInboxesDetails.communal_whatsapp_360_number;
    },
    fetchApiKey() {
      return this.whatsappInbox
        ? ''
        : this.communalInboxesDetails.communal_whatsapp_360_api;
    },
    getCommunalProvider(){
      return this.selectedProvider ? this.selectedProvider : this.communalInboxesDetails.provider;
    },
    async open360DialogPopup(){
      try {
        // disable the other button
        this.is360HubModalOpen = true;
        this.is360HubModalLoading = true;

        window.removeEventListener("message", this.messageListener);

        // Window options to be used in opening the new window
        const windowFeatures = "toolbar=no, menubar=no, width=600, height=900, top=100, left=100";
        const partnerId = "6MKi1UPA";
        const redirectUrl = `${window.location.origin}${window.location.pathname}`;

        // Open the new window with the specified URL
        const hub360Url = `https://hub.360dialog.com/dashboard/app/${partnerId}/permissions?redirect_url=${encodeURIComponent(redirectUrl)}&number=${encodeURIComponent(this.phoneNumber)}`;
        const newWindow = window.open(hub360Url,"integratedOnboardingWindow",windowFeatures);

        this.messageListener = (e) =>{
          this.receiveMessage360(e,newWindow);
        }

        // receive the calback url response to get the channel ID
        window.addEventListener("message", this.messageListener, false);
      } catch (error) {
        console.log(error);
      }
    },

    async receiveMessage360 (event,newWindow){
      try {
        // console.log("receiveMessage360 => ",event);
        const queryString = `${event.data}`;
        const params = new URLSearchParams(queryString);
        const channels = params.get("channels");
        const client = params.get("client");
        console.log('channels -> ',channels); // [y9MiLoCH]
        console.log('client -> ',client); // oaY9LLfUCL
        // call backend to create API
        if (channels.length) {
          const hubAPIRes = await Hub360Channel.createApi({channel_id:channels[0]});
          if (hubAPIRes.data?.api_key) {
            this.apiKey = hubAPIRes.data.api_key;
            // call to submit the form
            this.createChannel();
          }
        }
        newWindow.close();

        // enable the submit button
        this.is360HubModalOpen = false;
        this.is360HubModalLoading = false;
      } catch (error) {
        console.log(error);
      }
    },

  },
};
</script>
