<template>
  <div class="row">
    <div class="column content-box">
      <section>
        <div>
          <label class="font_sm filter_box" for="">
            {{$t('D_BOARD.DATE_LABEL')}}
            <FilterSelector
              :filterItemsList="filterItemsList"
              :hideGroupby="false"
              @date-range-change="onDateRangeChange"
            />
          </label>
        </div>
      </section>
      <Stats />
      <div class="leader_benifit">
        <div>
          <div v-if="isGoalBenifitTab">
            <h3 class="title">{{$t('D_BOARD.GOAL.TITLE')}}</h3>
            <p class="option">
              {{score.level_name}}
              <span class="icon">
                <fluent-icon icon="chevron-down" />
              </span>
            </p>
            <div class="goal_card_wrap">
              <div class="goal_card">
                <div>
                  <div class="circular-progress">
                    <svg viewBox="0 0 36 36" class="progress-svg">
                      <defs>
                        <linearGradient id="progress-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" stop-color="#8D25F6" />
                          <stop offset="100%" stop-color="#CDA2FB" />
                        </linearGradient>
                      </defs>
                      <path
                        class="progress-bg"
                        d="M18 2.0845
                          a 15.9155 15.9155 0 1 1 0 31.831
                          a 15.9155 15.9155 0 1 1 0 -31.831"
                      />
                      <path
                        class="progress-bar"
                        :stroke-dasharray="`${complete_percent}, 100`"
                        stroke="url(#progress-gradient)"
                        d="M18 2.0845
                          a 15.9155 15.9155 0 1 1 0 31.831
                          a 15.9155 15.9155 0 1 1 0 -31.831"
                      />
                    </svg>
                    <div class="progress-content">
                      <img src="~dashboard/assets/images/octo_img.png" alt="">
                      <!-- img content here {{complete_percent}} -->
                    </div>
                  </div>
                </div>
                <div class="card_body">
                  <div class="heading">
                    {{complete_percent}}%
                  </div>
                  <p class="task_label">
                    {{score.task_count - score.task_pending_count}}
                    {{$t('D_BOARD.STEP_INFO.LABLE_OF')}}
                    {{score.task_count}}
                    {{$t('D_BOARD.STEP_INFO.LABLE_END')}}
                  </p>
                  <div>
                    <button class="btn_3d" @click="onShowModalSeries('complete_tasks')">{{$t('D_BOARD.STEP_INFO.BTN')}}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="badge_cards">
              <div class="badge_card">
                <div class="badge_content">
                  <div>
                    <p class="badge_title">{{$t('D_BOARD.GOAL.BADGE_TITLE')}}</p>
                    <p class="badge_label">{{score.rank_name}}</p>
                  </div>
                  <div class="logo">
                    <img src="~dashboard/assets/images/badge.png" alt="">
                  </div>
                </div>
                <div>
                  <woot-button
                    class="btn_outline button--dashboard-outline"
                    variant="outline"
                    @click="onShowModalSeries('squid_level')"
                  >
                    {{$t('D_BOARD.GOAL.BADGE_BTN')}}
                  </woot-button>
                </div>
              </div>
              <div class="badge_card">
                <div class="badge_content">
                  <div>
                    <p class="badge_title">{{$t('D_BOARD.GOAL.LEADER_TITLE')}}</p>
                    <p class="badge_label">
                      {{score.leaderboard_position}}
                      <span class="position_suffix">{{getLiteralRanking(score.leaderboard_position)}}</span>
                    </p>
                  </div>
                  <div class="logo">
                    <img src="~dashboard/assets/images/leader_board.png" alt="">
                  </div>
                </div>
                <div>
                  <woot-button
                    class="btn_outline button--dashboard-outline"
                    variant="outline"
                  >
                    {{$t('D_BOARD.GOAL.LEADER_BTN')}}
                  </woot-button>
                </div>
              </div>

            </div>
            <!-- add start_date a& end_date for Stats Component API -->
            <!-- add when select the date option, call API -->
          </div>
          <!-- pass complete click method -->
          <ScoreBenifits v-else :toggleBenifitTab="toggleBenifitTab" :onClickCompleteNow="onShowModalSeries" />
        </div>
        <LeaderBoard />
      </div>

    </div>
    <div v-if="showModalSeries">
      <SquidLevelsModal v-if="activeModal === 'squid_level'" :isOpen="showModalSeries" :on-close="onCLoseModals" />
      <TasksCompleteModal v-if="activeModal === 'complete_tasks'" :isOpen="showModalSeries" :on-close="onCLoseModals" />
    </div>
    <ContestEventPopup />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import format from 'date-fns/format';
import FilterSelector from '../settings/reports/components/FilterSelector.vue';
import Stats from "./Stats.vue"
import ScoreBenifits from "./ScoreBenifits.vue"
import LeaderBoard from "./LeaderBoard.vue"
import SquidLevelsModal from "./SquidLevelsModal.vue"
import TasksCompleteModal from "./TasksCompleteModal.vue"
import ContestEventPopup from "../../../components/layout/sidebarComponents/popups/ContestEventPopup.vue"



export default {
  name: 'GoalHome',
  components: {
    FilterSelector,
    Stats,
    ScoreBenifits,
    LeaderBoard,
    SquidLevelsModal,
    TasksCompleteModal,
    ContestEventPopup,
  },
  data() {
    return {
      from: 0,
      to: 0,
      // rows: [],
      isGoalBenifitTab: false,
      // activeTab: "",
      showModalSeries: false,
      activeModal:"", // squid_level, complete_tasks
    };
  },
  computed: {
    ...mapGetters({
      score: 'score/getScore',
      leaderboard: 'score/getLeaderboard',
    }),
    filterItemsList() {
      return this.$store.getters['inboxes/getInboxes'] || [];
    },
    complete_percent() {
      const complete = this.score.task_count - this.score.task_pending_count;
      if (this.score.task_count === 0) {
          return 0;
      }
      const percent = (complete / this.score.task_count) * 100;
      return parseFloat(percent.toFixed(2));
    },
  },
  mounted(){
    const today = Math.floor(Date.now() / 1000);
    this.onDateRangeChange({ from: today, to: today });
    this.$store.dispatch('score/fetchScore');
    this.$store.dispatch('score/fetchLeaderboardList');
  },
  methods: {
    onDateRangeChange({ from, to }) {
      this.from = format(new Date(from * 1000), 'yyyy-MM-dd');
      this.to = format(new Date(to * 1000), 'yyyy-MM-dd');
      console.log({from:this.from,to:this.to});
      this.$nextTick(() => {
        this.fetchStatsData({from:this.from,to:this.to});
      });
    },
    fetchStatsData({from,to}){
      this.$store.dispatch('dashboard/fetchStats',{
        start_date: from,
        end_date: to
      })
    },
    toggleBenifitTab(){
      this.isGoalBenifitTab = !this.isGoalBenifitTab;
    },
    onCLoseModals(){
      this.activeModal = '';
      this.showModalSeries = false;
    },
    onShowModalSeries(modal_name){
      this.showModalSeries = true;
      this.activeModal = modal_name;
    },
    getLiteralRanking(n) {
      const suffix = ['th', 'st', 'nd', 'rd'];
      const value = n % 100;
      return suffix[(value - 20) % 10] || suffix[value] || suffix[0];
    },
    // end methods
  },
}
</script>

<style lang="scss" scoped>
.filter_box{}
.leader_benifit{
  display: grid;
  grid-template-columns: calc(70% - 20px) minmax(400px, 30%);
  gap: 20px;
  margin-top: 32px;

  .title{
    font-weight: 700;
    font-size: 20px;
    color: #000;
  }
  .option{
    display: flex;
    gap: 4px;
    margin-top: 4px;
    font-weight: 500;
    font-size: 14px;
    color: #8D25F6;
  }
}

.goal_card_wrap{
  padding: 3px;
  border-radius: 20px;
  margin-top: 16px;
  background: linear-gradient(180deg, #D2ACFC,#F2E7FE);
  .goal_card{
    display: grid;
    grid-template-columns: 160px 1fr;
    gap: 40px;
    padding: 32px 40px;
    border-radius: 20px;
    background: linear-gradient(45deg, #F2E7FE,#D2ACFC);
    .card_body{
      display: flex;
      flex-direction: column;
      justify-content: center;
      .heading{
        font-weight: 700;
        font-size: 36px;
        color: #8D25F6;
      }
      .task_label{
        font-weight: 500;
        font-size: 14px;
        color: #1F1F1F;
      }
      .btn_3d{
        margin-top: 16px;
        border-radius: 6px;
        background: linear-gradient(180deg,#8D25F6 75%,#531690);
        box-shadow: 0 2px 5px 0 #5907AB40;
        font-weight: 700;
        font-size: 14px;
        padding: 14px 24px;
        color: #fff;
        border: none;
        cursor: pointer;
      }

    }
  }
}

.badge_cards{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 16px;
  margin-top: 16px;
  .badge_card{
    padding: 20px;
    border-radius: 6px;
    border: 1px solid #EEEEEE;
    .badge_content{
      display: flex;
      justify-content: space-between;
    }

  }
}
.badge_content{
  div{
    .badge_title{
      font-weight: 500;
      font-size: 14px;
      color: #858585;
    }
    .badge_label{
      font-weight: 700;
      font-size: 20px;
      color: #1F1F1F;
      .position_suffix{
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .logo{
    img{
      width: 40px;
      height: 40px;
    }
  }
}


// circulate progress bar start
.circular-progress {
  position: relative;
  width: 160px;
  height: 160px;

  .progress-svg {
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
  }

  .progress-bg {
    fill: #EBFCC0;
    stroke: #EBFCC0;
    stroke-width: 2.8;
  }

  .progress-bar {
    fill: none;
    stroke: linear-gradient(45deg, #8d25f6, #cda2fb);
    stroke-width: 2.8;
    stroke-linecap: round;
    transition: stroke-dasharray 0.3s ease;
  }

  .progress-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
// circulate progress bar end

</style>

<style>
/* global styles  */
.filter_box .multiselect__single{
  font-size: 14px;
}

/* .leader_benifit .btn_outline{
  padding: 10px 24px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 6px;
  border: 1px solid #8D25F6;
  color: #8D25F6;
  background: #fff;
} */

</style>